/*eslint-disable*/
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const INPUT_FORM_CHANGE = 'INPUT_FORM_CHANGE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const VALIDATION_ERRORS = 'VALIDATION_ERRORS';
export const REGISTERED_USER = 'REGISTERED_USER';
export const SIGN_UP_FORM_CHANGE = 'SIGN_UP_FORM_CHANGE';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_STARTED = 'SIGN_UP_STARTED';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_UP_VALIDATION_ERRORS = 'SIGN_UP_VALIDATION_ERRORS';
export const LOGIN_VERIFICATION_SUCCESS = 'LOGIN_VERIFICATION_SUCCESS';
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_DEPARTMENT_FAILED = 'GET_DEPARTMENT_FAILED';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';

export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const CREATE_ORGANISATION_FAILED = 'CREATE_ORGANISATION_FAILED';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';

export const CREATE_ORGANISATION_VALIDATION_ERROR =
  'CREATE_ORGANISATION_VALIDATION_ERROR';

export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';

export const AUTH_TOKEN = 'idToken';
export const REFRESH_TOKEN = 'refreshToken';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const USER_ID = 'userId';
export const USER_EMAIL = 'email';
export const USER_ROLES = 'roles';
export const USER_DESIGNATION = 'designation';
export const PROFILE_PICTURE = 'profileImage';
export const CLIENT = 'clientData';

// get metadata
export const FETCH_METADATA_SUCCESS = 
  'FETCH_METADATA_SUCCESS'
export const FETCH_METADATA_FAILED = 
  'FETCH_METADATA_FAILED'

// craete organization group
export const CREATE_ORGANISATION_GROUP_SUCCESS =
  'CREATE_ORGANISATION_GROUP_SUCCESS';
export const CREATE_ORGANISATION_GROUP_FAILED =
  'CREATE_ORGANISATION_GROUP_FAILED';
export const CREATE_ORGANISATION_GROUP = 'CREATE_ORGANISATION_GROUP';
export const CREATE_ORGANISATION_GROUP_VALIDATION_ERROR =
  'CREATE_ORGANISATION_GROUP_VALIDATION_ERROR';

// get organization group list
export const LIST_ORGANISATIONS_GROUP_STARTED =
  'LIST_ORGANISATIONS_GROUP_STARTED';
export const LIST_ORGANISATIONS_GROUP_SUCCESS =
  'LIST_ORGANISATIONS_GROUP_SUCCESS';
export const LIST_ORGANISATIONS_GROUP_FAILED =
  'LIST_ORGANISATIONS_GROUP_FAILED';
//frtch organization of singel  group
export const LIST_GROUP_ORGANISATIONS_STARTED =
  'LIST_GROUP_ORGANISATIONS_STARTED';
export const LIST_GROUP_ORGANISATIONS_SUCCESS =
  'LIST_GROUP_ORGANISATIONS_SUCCESS';
export const LIST_GROUP_ORGANISATIONS_FAILED =
  'LIST_GROUP_ORGANISATIONS_FAILED';

export const FETCH_GROUP_DETAILS_STARTED = 'FETCH_GROUP_DETAILS_STARTED';
export const FETCH_GROUP_DETAILS_SUCCESS = 'FETCH_GROUP_DETAILS_SUCCESS';
export const FETCH_GROUP_DETAILS_FAILED = 'FETCH_GROUP_DETAILS_FAILED';

// get organisation list
export const LIST_ORGANISATIONS_STARTED = 'LIST_ORGANISATIONS_STARTED';
export const LIST_ORGANISATIONS_SUCCESS = 'LIST_ORGANISATIONS_SUCCESS';
export const LIST_ORGANISATIONS_FAILED = 'LIST_ORGANISATIONS_FAILED';

// get mission list
export const LIST_MISSIONS_STARTED = 'LIST_MISSIONS_STARTED';
export const LIST_MISSIONS_SUCCESS = 'LIST_MISSIONS_SUCCESS';
export const LIST_MISSIONS_FAILED = 'LIST_MISSIONS_FAILED';

// get mission list
export const FETCH_ORG_DETAILS_STARTED = 'FETCH_ORG_DETAILS_STARTED';
export const FETCH_ORG_DETAILS_SUCCESS = 'FETCH_ORG_DETAILS_SUCCESS';
export const FETCH_ORG_DETAILS_FAILED = 'FETCH_ORG_DETAILS_FAILED';

export const GET_MISSION_PROBLEM_TYPE_START = 'GET_MISSION_PROBLEM_TYPE_START';
export const GET_MISSION_PROBLEM_TYPE_FAILED =
  'GET_MISSION_PROBLEM_TYPE_FAILED';
export const GET_MISSION_PROBLEM_TYPE_SUCCESS =
  'GET_MISSION_PROBLEM_TYPE_SUCCESS';

export const SAVE_MISSION_START = 'SAVE_MISSION_START';
export const SAVE_MISSION_SUCCESS = 'SAVE_MISSION_SUCCESS';
export const SAVE_MISSION_FAILED = 'SAVE_MISSION_FAILED';

export const ON_CHANGE_OBJECTIVE = 'ON_CHANGE_OBJECTIVE';

export const GET_DEPARTMENTS_START = 'GET_DEPARTMENTS_START';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILED = 'GET_DEPARTMENTS_FAILED';

export const GET_DESIGNATIONS_START = 'GET_DESIGNATIONS_START';
export const GET_DESIGNATIONS_SUCCESS = 'GET_DESIGNATIONS_SUCCESS';
export const GET_DESIGNATIONS_FAILED = 'GET_DESIGNATIONS_FAILED';

export const ON_CHANGE_MISSION_FORM = 'ON_CHANGE_MISSION_FORM';

export const ADD_EMPLOYEE_START = 'ADD_EMPLOYEE_START';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILED = 'ADD_EMPLOYEE_FAILED';
export const RESET_EMPLOYEE_FORM = 'RESET_EMPLOYEE_FORM';

export const GET_EMPLOYEE_LIST_START = 'GET_EMPLOYEE_LIST_START';
export const GET_EMPLOYEE_LIST_SUCCESS = 'GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_FAILED = 'GET_EMPLOYEE_LIST_FAILED';

//add insights
export const ADD_INSIGHTS_FORM_CHANGES = 'ADD_INSIGHTS_FORM_CHANGES';

export const FETCH_QUESTIONS_STEP2_START = 'FETCH_QUESTIONS_STEP2_START';
export const FETCH_QUESTIONS_STEP2_SUCCESS = 'FETCH_QUESTIONS_STEP2_SUCCESS';
export const FETCH_QUESTIONS_STEP2_FAILED = 'FETCH_QUESTIONS_STEP2_SUCCESS';
export const FETCH_QUESTIONS_STEP2_SELECTED = 'FETCH_QUESTIONS_STEP2_SELECTED';
export const FETCH_QUESTIONS_STEP2_ONCHANGE = 'FETCH_QUESTIONS_STEP2_ONCHANGE';
export const UPDATE_QUEST_ANSWER = 'UPDATE_QUEST_ANSWER';
export const QUEST_SEND_MULTIPLE_ANSWERS_START =
  'QUEST_SEND_MULTIPLE_ANSWERS_START';
export const QUEST_SEND_MULTIPLE_ANSWERS_SUCCESS =
  'QUEST_SEND_MULTIPLE_ANSWERS_SUCCESS';
export const QUEST_SEND_MULTIPLE_ANSWERS_FAILED =
  'QUEST_SEND_MULTIPLE_ANSWERS_FAILED';

export const UPDATE_MISSION_DETAILS_START = 'UPDATE_MISSION_DETAILS_START';
export const UPDATE_MISSION_DETAILS_SUCCESS = 'UPDATE_MISSION_DETAILS_SUCCESS';
export const UPDATE_MISSION_DETAILS_FAILED = 'UPDATE_MISSION_DETAILS_FAILED';

export const FETCH_MISSION_DETAILS_START = 'FETCH_MISSION_DETAILS_START';
export const FETCH_MISSION_DETAILS_SUCCESS = 'FETCH_MISSION_DETAILS_SUCCESS';
export const FETCH_MISSION_DETAILS_FAILED = 'FETCH_MISSION_DETAILS_FAILED';

export const EVIDENCE_SEARCH_START = 'EVIDENCE_SEARCH_START';
export const EVIDENCE_SEARCH_SUCCESS = 'EVIDENCE_SEARCH_SUCCESS';
export const EVIDENCE_SEARCH_FAILED = 'EVIDENCE_SEARCH_FAILED';

export const TEAMINFO_GET_DEPARTMENT_LIST_START =
  'TEAMINFO_GET_DEPARTMENT_LIST_START';
export const TEAMINFO_GET_DEPARTMENT_LIST_SUCCESS =
  'TEAMINFO_GET_DEPARTMENT_LIST_SUCCESS';
export const TEAMINFO_GET_DEPARTMENT_LIST_FAILED =
  'TEAMINFO_GET_DEPARTMENT_LIST';
export const TEAMINFO_GET_DESIGNATION_LIST_START =
  'TEAMINFO_GET_DESIGNATION_LIST_START';
export const TEAMINFO_GET_DESIGNATION_LIST_SUCCESS =
  'TEAMINFO_GET_DESIGNATION_LIST_SUCCESS';
export const TEAMINFO_GET_DESIGNATION_LIST_FAILED =
  'TEAMINFO_GET_DESIGNATION_LIST_FAILED';
export const TEAMINFO_GET_EMPLOYEE_LIST_START =
  'TEAMINFO_GET_EMPLOYEE_LIST_START';
export const TEAMINFO_GET_EMPLOYEE_LIST_SUCCESS =
  'TEAMINFO_GET_EMPLOYEE_LIST_SUCCESS';
export const TEAMINFO_GET_EMPLOYEE_LIST_FAILED =
  'TEAMINFO_GET_EMPLOYEE_LIST_FAILED';

export const GET_TEAMDETAILS_TEAMINFO_START = 'GET_TEAMDETAILS_TEAMINFO_START';
export const GET_TEAMDETAILS_TEAMINFO_SUCCESS =
  'GET_TEAMDETAILS_TEAMINFO_SUCCESS';
export const GET_TEAMDETAILS_TEAMINFO_FAILED =
  'GET_TEAMDETAILS_TEAMINFO_FAILED';
export const ADD_NEW_MEMBER_TO_LIST = 'ADD_NEW_MEMBER_TP_LIST';

export const UPLOAD_IMAGE_STARTED = 'UPLOAD_IMAGE_STARTED';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

export const INTEGRATE_IMAGE_STARTED = 'INTEGRATE_IMAGE_STARTED';
export const INTEGRATE_IMAGE_SUCCESS = 'INTEGRATE_IMAGE_SUCCESS';
export const INTEGRATE_IMAGE_ERROR = 'INTEGRATE_IMAGE_ERROR';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_VALIDATION_ERROR =
  'RESET_PASSWORD_VALIDATION_ERROR';

export const ADD_MSOP_REPORT_START = 'ADD_MSOP_REPORT_START';
export const ADD_MSOP_REPORT_SUCCESS = 'ADD_MSOP_REPORT_SUCCESS';
export const ADD_MSOP_REPORT_FAILED = 'ADD_MSOP_REPORT_FAILED';
export const ADD_SPRINT_START = 'ADD_SPRINT_START';
export const ADD_SPRINT_SUCCESS = 'ADD_SPRINT_SUCCESS';
export const ADD_SPRINT_FAILED = 'ADD_SPRINT_FAILED';
export const ADD_SPRINT_VALIDATION_ERRORS = 'ADD_SPRINT_VALIDATION_ERRORS';
export const UPDATE_SPRINT_ERROR_MESSAGE = 'UPDATE_SPRINT_ERROR_MESSAGE';

export const UPDATE_SPRINT_START = 'UPDATE_SPRINT_START';
export const UPDATE_SPRINT_SUCCESS = 'UPDATE_SPRINT_SUCCESS';
export const UPDATE_SPRINT_FAILED = 'UPDATE_SPRINT_FAILED';

export const GET_SPRINTS_START = 'GET_SPRINTS_START';
export const GET_SPRINTS_SUCCESS = 'GET_SPRINTS_SUCCESS';
export const GET_SPRINTS_FAILED = 'GET_SPRINTS_FAILED';

export const FETCH_MSOP_REPORT_START = 'FETCH_MSOP_REPORT_START';
export const FETCH_MSOP_REPORT_SUCCESS = 'FETCH_MSOP_REPORT_SUCCESS';
export const FETCH_MSOP_REPORT_FAILED = 'FETCH_MSOP_REPORT_FAILED';

export const GET_MISSION_HYPOTHESIS_START = 'GET_MISSION_HYPOTHESIS_START';
export const GET_MISSION_HYPOTHESIS_SUCCESS = 'GET_MISSION_HYPOTHESIS_SUCCESS';
export const GET_MISSION_HYPOTHESIS_FAILED = 'GET_MISSION_HYPOTHESIS_FAILED';

export const GET_SPRINT_BY_ID_START = 'GET_SPRINT_BY_ID_START';
export const GET_SPRINT_BY_ID_SUCCESS = 'GET_SPRINT_BY_ID_SUCCESS';
export const GET_SPRINT_BY_ID_FAILED = 'GET_SPRINT_BY_ID_FAILED';

export const LIST_MSOP_REPORT_START = 'LIST_MSOP_REPORT_START';
export const LIST_MSOP_REPORT_SUCCESS = 'LIST_MSOP_REPORT_SUCCESS';
export const LIST_MSOP_REPORT_FAILED = 'LIST_MSOP_REPORT_FAILED';
export const GET_MEASUREMENTS_START = 'GET_MEASUREMENTS_START';
export const GET_MEASUREMENTS_SUCCESS = 'GET_MEASUREMENTS_SUCCESS';
export const GET_MEASUREMENTS_FAILED = 'GET_MEASUREMENTS_FAILED';

export const ADD_MEASUREMENTS_START = 'ADD_MEASUREMENTS_START';
export const ADD_MEASUREMENTS_SUCCESS = 'ADD_MEASUREMENTS_SUCCESS';
export const ADD_MEASUREMENTS_FAILED = 'ADD_MEASUREMENTS_FAILED';

export const GET_CADENCE_START = 'GET_CADENCE_START';
export const GET_CADENCE_SUCCESS = 'GET_CADENCE_SUCCESS';
export const GET_CADENCE_FAILED = 'GET_CADENCE_FAILED';

export const ADD_CADENCE_START = 'ADD_CADENCE_START';
export const ADD_CADENCE_SUCCESS = 'ADD_CADENCE_SUCCESS';
export const ADD_CADENCE_FAILED = 'ADD_CADENCE_FAILED';

export const MISSION_HYPOTHESIS_START = 'MISSION_HYPOTHESIS_START';
export const MISSION_HYPOTHESIS_SUCCESS = 'MISSION_HYPOTHESIS_SUCCESS';
export const MISSION_HYPOTHESIS_FAILED = 'MISSION_HYPOTHESIS_FAILED';

export const UPDATE_MEASUREMENTS_START = 'UPDATE_MEASUREMENTS_START';
export const UPDATE_MEASUREMENTS_SUCCESS = 'UPDATE_MEASUREMENTS_SUCCESS';
export const UPDATE_MEASUREMENTS_FAILED = 'UPDATE_MEASUREMENTS_FAILED';

export const GET_MISSION_TEAM_START = 'GET_MISSION_TEAM_START';
export const GET_MISSION_TEAM_SUCCESS = 'GET_MISSION_TEAM_SUCCESS';
export const GET_MISSION_TEAM_FAILED = 'GET_MISSION_TEAM_FAILED';

export const GET_SPRINT_TEAM_START = 'GET_SPRINT_TEAM_START';
export const GET_SPRINT_TEAM_SUCCESS = 'GET_SPRINT_TEAM_SUCCESS';
export const GET_SPRINT_TEAM_FAILED = 'GET_SPRINT_TEAM_FAILED';

export const ADD_SPRINT_TEAM_START = 'ADD_SPRINT_TEAM_START';
export const ADD_SPRINT_TEAM_SUCCESS = 'ADD_SPRINT_TEAM_SUCCESS';
export const ADD_SPRINT_TEAM_FAILED = 'ADD_SPRINT_TEAM_FAILED';

export const HYPOTHESIS_HEADING_START = 'HYPOTHESIS_HEADING_START';
export const HYPOTHESIS_HEADING_SUCCESS = 'HYPOTHESIS_HEADING_SUCCESS';
export const HYPOTHESIS_HEADING_ERROR = 'HYPOTHESIS_HEADING_ERROR';

export const DELETE_ORGANISATION_START = 'DELETE_ORGANISATION_START';
export const DELETE_ORGANISATION_SUCCESS = 'DELETE_ORGANISATION_SUCCESS';
export const DELETE_ORGANISATION_FAILED = 'DELETE_ORGANISATION_FAILED';

export const DELETE_GROUP_START = 'DELETE_GROUP_START';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const DELETE_SUMMARY_START = 'DELETE_SUMMARY_START';
export const DELETE_SUMMARY_FAILED = 'DELETE_SUMMARY_FAILED';
export const DELETE_SUMMARY_SUCCESS = 'DELETE_SUMMARY_SUCCESS';

export const UPDATE_ORGANISATION_START = 'UPDATE_ORGANISATION_START';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_FAILED = 'UPDATE_ORGANISATION_FAILED';

export const UPDATE_GROUP_START = 'UPDATE_GROUP_START';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

export const UPDATE_MISSION_START = 'UPDATE_MISSION_START';
export const UPDATE_MISSION_SUCCESS = 'UPDATE_MISSION_SUCCESS';
export const UPDATE_MISSION_FAILED = 'UPDATE_MISSION_FAILED';
export const UPDATE_CHANGE_PASSWORD_FORM = 'UPDATE_CHANGE_PASSWORD_FORM';
export const CHANGE_PASSWORD_ERRORS = 'CHANGE_PASSWORD_ERRORS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_STARTED = 'CHANGE_PASSWORD_STARTED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const DELETE_MISSION_START = 'DELETE_MISSION_START';
export const DELETE_MISSION_SUCCESS = 'DELETE_MISSION_SUCCESS';
export const DELETE_MISSION_FAILED = 'DELETE_MISSION_FAILED';

export const DELETE_SPRINT_START = 'DELETE_SPRINT_START';
export const DELETE_SPRINT_SUCCESS = 'DELETE_SPRINT_SUCCESS';
export const DELETE_SPRINT_FAILED = 'DELETE_SPRINT_FAILED';
export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';

export const DELETE_DEPARTMENT_START = 'DELETE_DEPARTMENT_START';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILED = 'DELETE_DEPARTMENT_FAILED';

export const DELETE_DESIGNATION_START = 'DELETE_DESIGNATION_START';
export const DELETE_DESIGNATION_SUCCESS = 'DELETE_DESIGNATION_SUCCESS';
export const DELETE_DESIGNATION_FAILED = 'DELETE_DESIGNATION_FAILED';

export const UPDATE_DEPARTMENT_START = 'UPDATE_DEPARTMENT_START';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILED = 'UPDATE_DEPARTMENT_FAILED';

export const UPDATE_DESIGNATION_START = 'UPDATE_DESIGNATION_START';
export const UPDATE_DESIGNATION_SUCCESS = 'UPDATE_DESIGNATION_SUCCESS';
export const UPDATE_DESIGNATION_FAILED = 'UPDATE_DESIGNATION_FAILED';

export const GET_ACCOUNT_INSIGHTS_START = 'GET_ACCOUNT_INSIGHTS_START';
export const GET_ACCOUNT_INSIGHTS_SUCCESS = 'GET_ACCOUNT_INSIGHTS_SUCCESS';
export const GET_ACCOUNT_INSIGHTS_FAILED = 'GET_ACCOUNT_INSIGHTS_FAILED';

export const ADD_ACCOUNT_INSIGHTS_START = 'ADD_ACCOUNT_INSIGHTS_START';
export const ADD_ACCOUNT_INSIGHTS_SUCCESS = 'ADD_ACCOUNT_INSIGHTS_SUCCESS';
export const ADD_ACCOUNT_INSIGHTS_FAILED = 'ADD_ACCOUNT_INSIGHTS_FAILED';

export const GET_USER_DEPARTMENT_START = 'GET_USER_DEPARTMENT_START';
export const GET_USER_DEPARTMENT_SUCCESS = 'GET_USER_DEPARTMENT_SUCCESS';
export const GET_USER_DEPARTMENT_FAILED = 'GET_USER_DEPARTMENT_FAILED';

export const ADD_USER_DEPARTMENT_START = 'ADD_USER_DEPARTMENT_START';
export const ADD_USER_DEPARTMENT_SUCCESS = 'ADD_USER_DEPARTMENT_SUCCESS';
export const ADD_USER_DEPARTMENT_FAILED = 'ADD_USER_DEPARTMENT_FAILED';

export const GET_USER_DESIGNATION_START = 'GET_USER_DESIGNATION_START';
export const GET_USER_DESIGNATION_SUCCESS = 'GET_USER_DESIGNATION_SUCCESS';
export const GET_USER_DESIGNATION_FAILED = 'GET_USER_DESIGNATION_FAILED';

export const ADD_USER_DESIGNATION_START = 'ADD_USER_DESIGNATION_START';
export const ADD_USER_DESIGNATION_SUCCESS = 'ADD_USER_DESIGNATION_SUCCESS';
export const ADD_USER_DESIGNATION_FAILED = 'ADD_USER_DESIGNATION_FAILED';

export const GET_USER_DETAILS_STARTED = 'GET_USER_DETAILS_STARTED';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const GET_NOTES_STARTED = 'GET_NOTES_STARTED';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAILED = 'GET_NOTES_FAILED';

export const ADD_NOTES_STARTED = 'ADD_NOTES_STARTED';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAILED = 'ADD_NOTES_FAILED';
export const GET_CONNECTED_NETWORKS_STARTED = 'GET_CONNECTED_NETWORKS_STARTED';
export const GET_CONNECTED_NETWORKS_SUCCESS = 'GET_CONNECTED_NETWORKS_SUCCESS';
export const GET_CONNECTED_NETWORKS_FAILED = 'GET_CONNECTED_NETWORKS_FAILED';

export const ADD_CONNECTED_NETWORK_STARTED = 'ADD_CONNECTED_NETWORK_STARTED';
export const ADD_CONNECTED_NETWORK_SUCCESS = 'ADD_CONNECTED_NETWORK_SUCCESS';
export const ADD_CONNECTED_NETWORK_FAILED = 'ADD_CONNECTED_NETWORK_FAILED';

export const DELETE_CONNECTED_NETWORK_STARTED =
  'DELETE_CONNECTED_NETWORK_STARTED';
export const DELETE_CONNECTED_NETWORK_SUCCESS =
  'DELETE_CONNECTED_NETWORK_SUCCESS';
export const DELETE_CONNECTED_NETWORK_FAILED =
  'DELETE_CONNECTED_NETWORK_FAILED';

export const DELETE_PERSONA_START = 'DELETE_PERSONA_START';
export const DELETE_PERSONA_SUCCESS = 'DELETE_PERSONA_SUCCESS';
export const DELETE_PERSONA_FAILED = 'DELETE_PERSONA_FAILED';

export const UPDATE_PERSONA_START = 'UPDATE_PERSONA_START';
export const UPDATE_PERSONA_SUCCESS = 'UPDATE_PERSONA_SUCCESS';
export const UPDATE_PERSONA_FAILED = 'UPDATE_PERSONA_FAILED';

export const FETCH_TEAM_BUILD_START = 'FETCH_TEAM_BUILD_START';
export const FETCH_TEAM_BUILD_SUCCESS = 'FETCH_TEAM_BUILD_SUCCESS';
export const FETCH_TEAM_BUILD_FAILED = 'FETCH_TEAM_BUILD_FAILED';

export const FETCH_OTHER_PERSON_START = 'FETCH_OTHER_PERSON_START';
export const FETCH_OTHER_PERSON_SUCCESS = 'FETCH_OTHER_PERSON_SUCCESS';
export const FETCH_OTHER_PERSON_FAILED = 'FETCH_OTHER_PERSON_FAILED';

export const ADD_TEAM_BUILD_START = 'ADD_TEAM_BUILD_START';
export const ADD_TEAM_BUILD_SUCCESS = 'ADD_TEAM_BUILD_SUCCESS';
export const ADD_TEAM_BUILD_FAILED = 'ADD_TEAM_BUILD_FAILED';

export const GET_TEAM_BUILD_START = 'GET_TEAM_BUILD_START';
export const GET_TEAM_BUILD_SUCCESS = 'GET_TEAM_BUILD_SUCCESS';
export const GET_TEAM_BUILD_FAILED = 'GET_TEAM_BUILD_FAILED';

export const ADD_SPRINT_LEARNING = 'ADD_SPRINT_LEARNING';
export const ADD_SPRINT_LEARNING_SUCCESS = 'ADD_SPRINT_LEARNING_SUCCESS';
export const ADD_SPRINT_LEARNING_FAILED = 'ADD_SPRINT_LEARNING_FAILED';

export const ADD_SPRINT_LEARNING_TABLE = 'ADD_SPRINT_LEARNING_TABLE';
export const ADD_SPRINT_LEARNING_TABLE_SUCCESS =
  'ADD_SPRINT_LEARNING_TABLE_SUCCESS';
export const ADD_SPRINT_LEARNING_TABLE_FAILED =
  'ADD_SPRINT_LEARNING_TABLE_FAILED';

export const GET_SPRINT_LEARNING = 'GET_SPRINT_LEARNING';
export const GET_SPRINT_LEARNING_SUCCESS = 'GET_SPRINT_LEARNING_SUCCESS';
export const GET_SPRINT_LEARNING_FAILED = 'GET_SPRINT_LEARNING_FAILED';

export const GET_SPRINT_TABLE_LEARNING = 'GET_SPRINT_TABLE_LEARNING';
export const GET_SPRINT_LEARNING_TABLE_SUCCESS =
  'GET_SPRINT_LEARNING_TABLE_SUCCESS';
export const GET_SPRINT_LEARNING_TABLE_FAILED =
  'GET_SPRINT_LEARNING_TABLE_FAILED';

export const GET_SPRINT_TABLE_TOPICS_LEARNING =
  'GET_SPRINT_TABLE_TOPICS_LEARNING';
export const GET_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS =
  'GET_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS';
export const GET_SPRINT_LEARNING_TOPICS_TABLE_FAILED =
  'GET_SPRINT_LEARNING_TOPICS_TABLE_FAILED';

export const ADD_SPRINT_LEARNING_TOPICS_TABLE =
  'ADD_SPRINT_LEARNING_TOPICS_TABLE';
export const ADD_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS =
  'ADD_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS';
export const ADD_SPRINT_LEARNING_TOPICS_TABLE_FAILED =
  'ADD_SPRINT_LEARNING_TOPICS_ABLE_FAILED';

export const ADD_ORGANIZATION_SPRINT_LEARNING =
  'ADD_ORGANIZATION_SPRINT_LEARNING';
export const ADD_ORGANIZATION_SPRINT_LEARNING_SUCCESS =
  'ADD_ORGANIZATION_SPRINT_LEARNING_SUCCESS';
export const ADD_ORGANIZATION_SPRINT_LEARNING_FAILED =
  'ADD_ORGANIZATION_SPRINT_LEARNING_FAILED';

export const ADD_ORGANIZATION_SPRINT_LEARNING_TABLE =
  'ADD_ORGANIZATION_SPRINT_LEARNING_TABLE';
export const ADD_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS =
  'ADD_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS';
export const ADD_ORGANIZATION_SPRINT_LEARNING_TABLE_FAILED =
  'ADD_ORGANIZATION_SPRINT_LEARNING_TABLE_FAILED';

export const GET_ORGANIZATION_SPRINT_LEARNING =
  'GET_ORGANIZATION_SPRINT_LEARNING';
export const GET_ORGANIZATION_SPRINT_LEARNING_SUCCESS =
  'GET_ORGANIZATION_SPRINT_LEARNING_SUCCESS';
export const GET_ORGANIZATION_SPRINT_LEARNING_FAILED =
  'GET_ORGANIZATION_SPRINT_LEARNING_FAILED';

export const GET_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS =
  'GET_ORGANIZATION_SPRINT_LEARNING_TABLE_SUCCESS';
export const GET_ORGANIZATION_SPRINT_LEARNING_TABLE_FAILED =
  'GET_ORGANIZATION_SPRINT_LEARNING_TABLE_FAILED';

export const GET_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS =
  'GET_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS';
export const GET_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_FAILED =
  'GET_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_FAILED';

export const ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE =
  'ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE';
export const ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS =
  'ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_SUCCESS';
export const ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_TABLE_FAILED =
  'ADD_ORGANIZATION_SPRINT_LEARNING_TOPICS_ABLE_FAILED';

export const ADD_SPRINT_HUDDLE = 'ADD_SPRINT_HUDDLE';
export const ADD_SPRINT_HUDDLE_SUCCESS = 'ADD_SPRINT_HUDDLE_SUCCESS';
export const ADD_SPRINT_HUDDLE_FAILED = 'ADD_SPRINT_HUDDLE_FAILED';

export const GET_SPRINT_HUDDLE = 'GET_SPRINT_HUDDLE';
export const GET_SPRINT_HUDDLE_SUCCESS = 'GET_SPRINT_HUDDLE_SUCCESS';
export const GET_SPRINT_HUDDLE_FAILED = 'GET_SPRINT_HUDDLE_FAILED';

export const GET_SPRINT_TABLE_HUDDLE = 'GET_SPRINT_TABLE_HUDDLE';
export const GET_SPRINT_TABLE_HUDDLE_SUCCESS =
  'GET_SPRINT_TABLE_HUDDLE_TABLE_SUCCESS';
export const GET_SPRINT_TABLE_HUDDLE_TABLE_FAILED =
  'GET_SPRINT_TABLE_HUDDLE_TABLE_FAILED';

export const GET_SPRINT_TABLE_TOPICS_VALUES = 'GET_SPRINT_TABLE_TOPICS_VALUES';
export const GET_SPRINT_TABLE_TOPICS_VALUES_SUCCESS =
  'GET_SPRINT_TABLE_TOPICS_VALUES_SUCCESS';
export const GET_SPRINT_TABLE_TOPICS_VALUES_FAILED =
  'GET_SPRINT_TABLE_TOPICS_VALUES_FAILED';

export const GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES =
  'GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES';
export const GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES_SUCCESS =
  'GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES_SUCCESS';
export const GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES_FAILED =
  'GET_ORGANIZATION_SPRINT_TABLE_TOPICS_VALUES_FAILED';

export const GET_MOST_VALUALE_START = 'GET_MOST_VALUALE_START';
export const GET_MOST_VALUALE_SUCCESS = 'GET_MOST_VALUALE_SUCCESS';
export const GET_MOST_VALUALE_FAILED = 'GET_MOST_VALUALE_FAILED';

export const MOST_VALUALE_START = 'MOST_VALUALE_START';
export const MOST_VALUALE_SUCCESS = 'MOST_VALUALE_SUCCESS';
export const MOST_VALUALE_FAILED = 'MOST_VALUALE_FAILED';

export const GET_NETWORKING_TOOL_START = 'GET_NETWORKING_TOOL_START';
export const GET_NETWORKING_TOOL_SUCCESS = 'GET_NETWORKING_TOOL_SUCCESS';
export const GET_NETWORKING_TOOL_FAILED = 'GET_NETWORKING_TOOL_FAILED';

export const GET_USER_LIST_START = `GET_USER_LIST_START`
export const GET_USER_LIST_SUCCESS = `GET_USER_LIST_SUCCESS`
export const GET_USER_LIST_FAILED = `GET_USER_LIST_FAILED`

export const DELETE_USER_START = `DELETE_USER_START`
export const DELETE_USER_FAILED = `DELETE_USER_FAILED`
export const DELETE_USER_SUCCESS = `DELETE_USER_SUCCESS`
