import {Icon} from 'Components/atoms';
import { useLoading } from "Components/FullScreenLoader/LoadingContext";
import {DeleteOutlined} from '@ant-design/icons';
import {Card, Input} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from "react-i18next"
import {
  PftJourneyCompetencyOption,
  PftJourneyCompetency,
  PftJourneyImprovementTactic,
} from 'types/PftJourney';
import {
  postPftCompetency,
  postPftJourneyImprovementTactic,
  deleteTJCompetencyImprovementTactic,
  patchPatCompetencyNotes,
  postPatCompetencyNotes,
  deleteTJCompetencyById,
} from 'Services/PftJourney.service';

import {getImprovementTacticInfo} from 'Services/ImprovementTactic.service';
import {openImprovementTacticModal} from 'ReduxV2/state';
import {DifficultyTag} from 'Components/atoms';
import {NetworkMeasureSelect} from 'Components/atoms/select';
import {getNotification} from 'Components/GetNotification';
import ModalComponent from 'Components/Modal/ModalComponent';
import { number } from 'prop-types';

const {TextArea} = Input;

type HeaderProps = {
  title: string;
  onOpenClick: () => void;
  isOpen: boolean;
  transJourney: number | null;
  onAddClick: () => void;
  onRemoveClick: () => void;
};

type PftJourneyImprovementTacticWithEvents = PftJourneyImprovementTactic & {
  isDisabled: boolean;
  onClickHandle: () => void;
  onClickInfo: () => void;
};

const Header: React.FC<HeaderProps> = ({
  title,
  onOpenClick,
  isOpen,
  transJourney,
  onAddClick,
  onRemoveClick,
}) => {
  const addedToTJ = Boolean(transJourney);

  return (
    <div className='py-1.5 flex justify-between items-center w-full'>
      <button onClick={onOpenClick} className='shrink'>
        <h2 className='text-left'>{title}</h2>
      </button>
      <div className='flex items-center gap-4'>
        <button
          data-testid='addMilestoneButton'
          className={`rounded  py-2 px-4 text-white hover:bg-primary-hover ${
            addedToTJ ? 'bg-primary-hover' : 'bg-primary'
          }`}
          onClick={onAddClick}
          disabled={addedToTJ ? true : false}>
          <span className='whitespace-nowrap'>
            {addedToTJ ? 'Added' : 'Add Milestone'}
          </span>
          <span></span>
        </button>
        {addedToTJ && (<a
          data-testid='deleteMilestoneButton'
          className='ml-10'
          onClick={onRemoveClick}
         >
         <DeleteOutlined style={{fontSize:'1.5rem', color: '#888895'}} />
        </a>)}
 
        <button onClick={onOpenClick} className='shrink-0'>
          <Icon
            name='chevron-right'
            className={`w-5 h-5 transition-transform${
              isOpen ? ' -rotate-90' : ''
            }`}
          />
        </button>
      </div>
    </div>
  );
};

const Information: React.FC<{description: string}> = ({description}) => {
  // i18n translation hook
  const [t] = useTranslation()

  return(
  <div className='py-3'>
    <span className='font-semibold text-lg leading-[1.444]'>
      {t('Milestone_Detail_Card.Info')}:{' '}
    </span>
    <span className='font-medium text-sm leading-[1.571]'>{description}</span>
  </div>
)};

const Improvements: React.FC<{
  improvements: string[];
  lagMeasureImprovements: string[];
}> = ({improvements, lagMeasureImprovements}) => {
  // i18n translation hook
  const [t] = useTranslation()
  return(
  <div className='grid md:!flex gap-2'>
    <div className='flex-1'>
      <h3>{t('Milestone_Detail_Card.Improve_what')}?</h3>
      <ul>
        {improvements.map((improvement, index) => (
          <li key={index}>{improvement}</li>
        ))}
      </ul>
    </div>
    <div className='flex-1'>
      <h3>{t('Milestone_Detail_Card.Lag_improve')}?</h3>
      <ul>
        {lagMeasureImprovements.map((improvement, index) => (
          <li key={index}>{improvement}</li>
        ))}
      </ul>
    </div>
  </div>
)};

const StateAndMetrics: React.FC<{
  currentState: string;
  targetState: string;
  tjCompetencyId: number | null;
  missionMeasureIds: number[];
  tjcMissionMeasures: any[];
}> = ({
  currentState,
  targetState,
  tjCompetencyId,
  missionMeasureIds,
  tjcMissionMeasures,
}) => {

// i18n translation hook
const [t] = useTranslation()

  return (
    <div className='grid lg:!flex lg:justify-between gap-3'>
      <dl className='grid lg:grid-cols-[max-content_1fr] gap-x-2 gap-y-3'>
        <dt className='font-bold text-negative'>{t('Milestone_Detail_Card.Current_state')}:</dt>
        <dd className='max-w-[625px] font-medium'>{currentState}</dd>
        <dt className='font-bold text-positive'>{t('Milestone_Detail_Card.Target_state')}:</dt>
        <dd className='max-w-[625px] font-medium'>{targetState}</dd>
      </dl>
      <div className='min-w-[300px]'>
        <label className='grid gap-1'>
          <span className='font-bold'>{t('Milestone_Detail_Card.Outcome_measures')}:</span>
          {!!tjCompetencyId && (
            <NetworkMeasureSelect
              tjCompetencyId={tjCompetencyId}
              defaultValue={missionMeasureIds}
              tjcMissionMeasures={tjcMissionMeasures}
            />
          )}
          {!tjCompetencyId && (
            <NetworkMeasureSelect
              disabled={true}
              tjCompetencyId={tjCompetencyId}
              defaultValue={[]}
              tjcMissionMeasures={[]}
            />
          )}
        </label>
      </div>
    </div>
  );
};

const ImprovementTactic: React.FC<PftJourneyImprovementTacticWithEvents> = ({
  id,
  isDisabled,
  description,
  onClickHandle,
  onClickInfo,
  tjc,
  tier,
}) => {
  const isAdded = Boolean(tjc);

  // i18n translation hook
  const [t] = useTranslation()
  const lines = description.split('\n');

  return (
    <div>
      <div className='flex gap-2 items-center'>
        <button
          className={`flex items-center ${
            isDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={onClickHandle}
          disabled={isDisabled}>
          {isAdded ? (
            <Icon
              name={isAdded ? 'check-green-square' : 'plus-square'}
              className='w-6 h-6'
            />
          ) : (
            <Icon name='plus-square' className='w-6 h-6' />
          )}
        </button>
        <span className='font-semibold'>{t('Milestone_Detail_Card.Improvement_tactic')} #{id}: </span>
        <button className='flex items-center' onClick={onClickInfo}>
          <Icon name='information-circle' className='w-5 h-5' />
        </button>
        {!!tier && <DifficultyTag difficulty={tier} />}
      </div>
      <div style={{ whiteSpace: 'pre-line' }}>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const ImprovementTactics: React.FC<{
  tactics: PftJourneyImprovementTactic[];
  tjCompetency: number | null;
}> = ({tactics, tjCompetency}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const [maxHeight, setMaxHeight] = useState('0px');
  const [improvementTactics, setImprovementTactics] = useState<any>(
    tactics ?? []
  );

  const contentSpace = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { startLoading, stopLoading } = useLoading();

  const loadImprovementTacticInfo = async (tacticId: number) => {
    try {
      const res = await getImprovementTacticInfo(tacticId).call;
      if (res?.data) {
        dispatch(openImprovementTacticModal(res.data[0]));
      }
    } catch (error) {}
  };

  const addTacticToTJC = async (tacticId: number, tjc: any) => {
    try {
      startLoading()
      if (tjCompetency) {
        if(tjc == null){
          const res = await postPftJourneyImprovementTactic(
            tjCompetency,
            tacticId
          ).call;
          if (res?.data) {
            getNotification('success', {
              header: 'Success',
              body: 'Improvement tactic added to milestone',
            });
          }
          const newImprovementTactics = improvementTactics.map((item: any) => {
            if (item.id === tacticId) {
              return {
                ...item,
                tjc: res?.data?.id,
              };
            }
            return item;
          });
          setImprovementTactics(newImprovementTactics);
        }else{
          await deleteTJCompetencyImprovementTactic(tjc).call;
          const newImprovementTactics = improvementTactics.map((item: any) => {
            if (item.id === tacticId) {
              return {
                ...item,
                tjc: null,
              };
            }
            return item;
          })
          setImprovementTactics(newImprovementTactics);
          getNotification('success', {
            header: 'Success',
            body: 'Improvement tactic removed from milestone.',
          });
        }
      }
    } catch (error) {}
    stopLoading()
  };

  useEffect(() => {
    // @todo ascertain height better (or remove the animation; this section can be quite tall)
    if (contentSpace?.current) {
      setMaxHeight(`${contentSpace.current.scrollHeight + 1500}px`);
    }

    const tacticsWithTJCompetency = tactics.map((tactic) => {
      return {
        ...tactic,
        tjCompetency,
      };
    });
    setImprovementTactics(tacticsWithTJCompetency);
  }, []);

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <div>
      <div className='flex gap-4'>
        <span className='font-bold'>{t('Milestone_Detail_Card.Improvement_tactics')}: </span>
        <button
          onClick={toggle}
          className='font-medium text-primary underline hover:text-primary-hover'>
          {isOpen ? 'Hide' : 'Show'}
        </button>
      </div>
      <div
        ref={contentSpace}
        style={{maxHeight: isOpen ? maxHeight : '0px'}}
        className='overflow-hidden transition-all'>
        <div className='grid gap-2 pt-3'>
          {improvementTactics.map((item: any, index: number) => (
            <ImprovementTactic
              {...item}
              key={index}
              isDisabled={!Boolean(tjCompetency)}
              onClickHandle={() => {
                addTacticToTJC(item.id,item.tjc);
              }}
              onClickInfo={() => {
                loadImprovementTacticInfo(item.id);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const MilestoneDetailCard: React.FC<{
  competency: PftJourneyCompetency;
  current_option: PftJourneyCompetencyOption;
  target_option: PftJourneyCompetencyOption;
  pftId: number;
  tjCompetencyId: number | null;
  missionMeasureIds: number[];
  tjcMissionMeasures: any[];
  onDeleteCallback: any;
}> = ({
  competency,
  current_option,
  target_option,
  pftId,
  tjCompetencyId,
  missionMeasureIds,
  tjcMissionMeasures,
  onDeleteCallback,
}) => {
  let debounceTimer: any;
  const patId = useSelector((state: any) => state.pat.value);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<string>('0px');
  const [transJourney, setTransJourney] = useState<number | null>(
    competency.trans_journey
  );
  const [tjCompetency, setTjCompetency] = useState<number | null>(
    tjCompetencyId
  );
  const [competencyNotes, setCompetencyNotes] = useState<string>(
    //@ts-ignore
    competency?.notes?.notes ?? null
  );
  const [competencyNotesId, setCompetencyNotesId] = useState<number | null>(
    //@ts-ignore
    competency?.notes?.id ?? null
  );
  const [isOverwriteModalVisible, setIsOverwriteModalVisible] = useState(false)

  const { startLoading, stopLoading } = useLoading();
  const contentSpace = useRef<HTMLDivElement | null>(null);

  const addToTJ = async (id: number) => {
    try {
      if (pftId) {
        const res = await postPftCompetency(pftId, id).call;
        setTransJourney(res?.data?.trans_journey);
        setTjCompetency(res?.data?.id);
      }
    } catch (error) {}
  };

  const saveCompetencyNotes = async (notes: string) => {
    try {
      if (!competencyNotesId) {
        const res = await postPatCompetencyNotes({
          user_pat: patId,
          pat_competency: competency.id,
          notes: notes,
        }).call;
        if (res.data) {
          setCompetencyNotesId(res.data.id);
          setCompetencyNotes(res.data.notes);
          getNotification('success', {
            header: 'Success',
            body: 'Notes created',
          });
        }
      } else {
        const res = await patchPatCompetencyNotes(competencyNotesId, notes)
          .call;
        if (res.data) {
          setCompetencyNotes(res.data.notes);
          getNotification('success', {
            header: 'Success',
            body: 'Notes updated',
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (contentSpace?.current) {
      setMaxHeight(`${contentSpace.current.scrollHeight + 1500}px`);
    }
  }, []);


  const handleCancel = () => {
      setIsOverwriteModalVisible(false)
  }

  const handleDeleteItem = () => {
    if (competency?.id && tjCompetency) {
      startLoading()
      deleteTJCompetencyById(tjCompetency);
      handleCancel()
      setTimeout(() => {onDeleteCallback();}, 1000)
      stopLoading()
    }
  }
  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Card className='rounded-lg border-gray-400'>
      <ModalComponent
        handleOk={handleDeleteItem}
        handleCancel={handleCancel}
        open={isOverwriteModalVisible}
        title={'Delete'}
        contentMessage={'Are you sure to delete the milestone? All work wil be lost.'}
        cancelText={'Cancel'}
        okText={'Confirm'} 
      />
      <Header
        title={competency?.title?.includes('Milestone #') ? competency?.title : `Milestone #${competency?.ref_id} - ${competency?.title}`}
        isOpen={isOpen}
        onOpenClick={() => setIsOpen(!isOpen)}
        transJourney={transJourney}
        onAddClick={() => {
          if (competency?.id) {
            addToTJ(competency.id);
          }
        }}
        onRemoveClick={() => {
          if (competency?.id) {
            setIsOverwriteModalVisible(true);
          }
        }}
 
      />
      <div
        className='grid gap-3 overflow-hidden transition-all'
        ref={contentSpace}
        style={{maxHeight: isOpen ? maxHeight : '0px'}}>
        <StateAndMetrics
          currentState={current_option?.description ?? ''}
          targetState={target_option?.description ?? ''}
          tjCompetencyId={tjCompetency}
          missionMeasureIds={missionMeasureIds}
          tjcMissionMeasures={tjcMissionMeasures}
        />
        <Information
          description={competency?.about ?? 'No description found'}
        />
        <Improvements
          improvements={competency?.important?.split('\n') ?? []}
          lagMeasureImprovements={competency?.lag_measures?.split('\n') ?? []}
        />
        <div>
          <label>
            <span className='font-bold text-base leading-normal'>{t('Milestone_Detail_Card.Notes')}:</span>
            <TextArea
              rows={4}
              placeholder='Type here'
              defaultValue={competencyNotes}
              onChange={(e) => {
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => {
                  setCompetencyNotes(e.target.value);
                  saveCompetencyNotes(e.target.value);
                }, 1000);
              }}
            />
          </label>
        </div>
        <ImprovementTactics
          tactics={competency?.improvement_tactics ?? []}
          tjCompetency={tjCompetency}
        />
      </div>
    </Card>
  );
};
