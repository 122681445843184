import React, {useEffect, useState} from 'react';
import {SearchSelect} from 'Components/atoms/select/SearchSelect';
import {PlusSquareFilled} from '@ant-design/icons';
import {Col, DatePicker, Input, Menu, Modal, Row, Tabs} from 'antd';
import moment from 'moment';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {getNotification} from '../../../../../../Components/GetNotification';
import {MemberModal} from '../../../../../../Components/MemberModal';
import {
  addMemberToEntity,
  getMemberToEntity,
} from '../../../../../../Services/Members.service';
import {Overview} from '../Overview';
import {Sprints} from '../Sprints';
import {KeyDrivers} from '../KeyDrivers';
import {SmartSolutions} from '../SmartSolutions';
import {MVOP} from '../MVOP';
import {
  deleteMissionNew,
  getMissionDetail,
  getMissionsNew,
  patchMissions,
  postMission,
} from '../../../../../../Services/Mission.service';
import {getSprint} from '../../../../../../Services/Sprints.service';
import './styles/Mission.scss';
import {useDispatch, useSelector} from 'react-redux';
import {
  resetMission,
  selectMission,
} from '../../../../../../ReduxV2/state/missions';
import {selectSprint} from '../../../../../../ReduxV2/state/sprints';
import 'react-circular-progressbar/dist/styles.css';
import {cleanSprint} from '../../../../../../ReduxV2/state';
import Pagination from '../../../../../../Components/Pagination';
import StatusAndTrend from './components/StatusAndTrend/StatusAndTrend';
import {MissionCard} from 'Components/molecules/card';

import { useTranslation } from "react-i18next"
import TagSelect from 'Components/TagSelect';
import { TagType } from 'types/Tags';
import { removeTagsForArray } from 'Services/Tag.service';

const {TabPane} = Tabs;
const {Search} = Input;

/**
 * Renders a component for mission.
 *
 * @return {JSX.Element} The rendered component.
 */
const Mission = () => {
  const [queryString, setQueryString] = useState('');
  const [defaultActiveKey, setDefaultActiveKey] = useState('1');
  const [selectId, setSelectId] = useState('');
  const [memberData, setMemberData] = useState({
    email: '',
    permission: '',
    visible: false,
    data: [],
  });
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);
  const [allMissionList, setAllMissionList] = useState();
  const [sprint, setSprint] = useState({});
  const [otherOption, setOtherOption] = useState(false);
  const [currentMissionList, setCurrentMissionList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(8);
  const [sprintId, setSprintId] = useState(0);
  const [missionId, setMissionId] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsToBeRemoved, setTagsToBeRemoved] = useState([]);
  const [tagRefreshCounter, setTagRefreshCounter] = useState(0);

  const [t] = useTranslation()

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: {errors},
    watch,
    getValues,
  } = useForm();

  const {id} = useParams();
  const dispatch = useDispatch();

  const updatePage = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPageLimit(pageSize);
    setCurrentMissionList(
      allMissionList?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
    );
  };

  const dataMissionSelect = useSelector((state) => {
    return state.mission.value;
  });

  const sprint_id = useSelector((state) => {
    return state.sprint?.value;
  });

  const typeEntity = 'missionmembers';

  /**
   * The menu option select function.
   *
   * @param {Event} e - The event object.
   * @return {undefined} No return value.
   */
  const menuOptionSelect = (e) => {
    switch (e.key) {
      case '0':
        actionModal(true, selectId);
        break;
      case '1':
        deleteMission(selectId);
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={menuOptionSelect}>
      <Menu.Item key='0'>Edit</Menu.Item>
      <Menu.Item key='1'>Delete</Menu.Item>
    </Menu>
  );

  const loadAllMissions = async () => {
    try {
      const res = await getMissionsNew(id, queryString).call;
      setAllMissionList(res.data);
    } catch (error) {}
  };

  const loadSprint = async () => {
    if (sprint_id === 0) {
      setSprint({});
      return;
    }
    try {
      const res = await getSprint(sprint_id).call;
      setSprint(res.data);
    } catch (error) {}
  };

  /**
   * Delete a mission with the given ID.
   *
   * @param {any} id - The ID of the mission to delete.
   * @return {Promise<void>} A promise that resolves when the mission is deleted.
   */
  const deleteMission = async (id) => {
    try {
      const res = await deleteMissionNew(id).call;
      loadAllMissions();
    } catch (error) {}
  };

  /**
   * Saves a mission.
   *
   * @param {Object} params - The parameters for the mission.
   * @return {Promise} A promise that resolves with the result of saving the mission.
   */
  const saveMission = async (params) => {
    try {
      const data = {
        ...params,
        organization: id,
      };

      data.tags = params.tags || [];

      data.other_problem = otherOption ? params.other_problem : null;

      if (data?.id) {
        data.call_success_fail = data?.call_success_fail?.id;
        if(tagsToBeRemoved?.length > 0) {
          removeTagsForArray(tagsToBeRemoved, TagType.MISSION, data?.id);
          setTagRefreshCounter(prev => prev + 1);
        }
        await patchMissions(data).call;
      } else {
        await postMission(data).call;
      }

      setVisibleCreateModal(false);
      getNotification('success', {
        header: 'success',
        body: 'Project created successfully',
      });
    } catch (error) {}
  };

  /**
   * Updates the email property of the memberData object.
   *
   * @param {string} email - The new email value.
   * @return {undefined} This function does not return anything.
   */
  const emailChange = (email) => {
    setMemberData({...memberData, email: email});
  };

  /**
   * Updates the permission of a member in the member data.
   *
   * @param {type} permission - the new permission value
   * @return {type} undefined
   */
  const permissionChange = (permission) => {
    setMemberData({...memberData, permission: permission});
  };

  /**
   * A function that handles the action modal.
   *
   * @param {boolean} action - the action to be performed
   * @param {number} id - the ID of the action
   */
  const actionModal = async (action = false, id = 0) => {
    setTagRefreshCounter(prev => prev + 1);
    reset();
    if (id) {
      const res = await getMissionDetail(id).call;
      Object.keys(res?.data)?.map((key) => {
        if(key === 'tags'){
          setSelectedTags(res?.data[key]?.map(t => t?.name))
        } else {
          setValue(key, res?.data[key]);
        }
      });
      if (res?.data.other_problem) {
        setOtherOption(true);
      }
      setVisibleCreateModal(action);
    } else {
      setValue('start', '');
      setValue('end', '');
      setVisibleCreateModal(action);
      !action && setOtherOption(false);
    }
  };

  const handleOk = async () => {
    try {
      const res = await addMemberToEntity({
        entity_name: typeEntity,
        entity_id: selectId,
        email: memberData.email,
        action: memberData.permission || 'view',
      }).call;
      if (res) {
        handleCancel();
        getNotification('success', {
          header: 'success',
          body: 'User successfully linked',
        });
      }
    } catch (err) {
      getNotification('error', {
        header: 'error',
        body: 'User Does not Exist',
      });
    }
  };

  const handleCancel = () => {
    setMemberData({email: '', permission: '', visible: false, data: []});
  };

  useEffect(() => {
    loadAllMissions();
    dispatch(resetMission());
  }, [visibleCreateModal]);

  useEffect(() => {
    loadSprint();
  }, [sprint_id]);

  useEffect(() => {
    updatePage(1, pageLimit);
  }, [allMissionList]);

  useEffect(() => {
    loadAllMissions();
  }, [queryString, dataMissionSelect, id]);

  useEffect(() => {
    if (sprintId == 0 || missionId == 0) return;
    const mission = currentMissionList?.filter(
      (mission) => mission.id == missionId
    );
    if ((mission?.length || 0) === 0) return;
    dispatch(selectMission(mission[0]));
    setDefaultActiveKey('5');
    dispatch(selectSprint({id: sprintId}));
    return () => {
      localStorage.removeItem('mission_id');
      localStorage.removeItem('sprint_id');
      setMissionId(0);
      setSprintId(0);
    };
  }, [currentMissionList, missionId, sprintId]);

  useEffect(() => {
    setMissionId(localStorage.getItem('mission_id'));
    setSprintId(localStorage.getItem('sprint_id'));
  }, []);

  useEffect(() => {
    setValue('tags', selectedTags);
  }, [selectedTags]);

  return (
    <div className='mission'>
      {!dataMissionSelect?.id ? (
        <div>
          <h1>Projects</h1>
          <div className='flex w-50 ml-auto mb-15'>
            <div 
              data-testid='addButton'
              className='flex-vertical'
              onClick={() => actionModal(true)}
            >
              <PlusSquareFilled
                style={{
                  fontSize: '35px',
                  color: '#3C5DD6',
                  cursor: 'pointer',
                  margin: 'auto',
                  marginLeft: '10px',
                }}
              />
            </div>
            <Search
              className='border-r-50'
              onSearch={(value) => setQueryString(value)}
            />
          </div>
          {!currentMissionList?.length && (
            <div className='text-center bg-white initalCardMission border-r-15 font-17'>
              <p>
                <b> Let's Begin!</b>
                <br />
                Here is the place where you can see the case studies you
                created. You may define a project by creating it as a project.
                Pin the project as favorite, archive it to denote as project
                on-hold, and also edit it once you create it.
              </p>
              <p className='mt-15'>
                {' '}
                Start creating projects by clicking the “Add button” on the top.
              </p>
            </div>
          )}

          <div className='grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
            {currentMissionList?.map((mission) => (
              <MissionCard
                tags={mission?.tags ?? []}
                key={mission.id}
                name={mission.name}
                status={mission?.status}
                start={mission.start}
                end={mission.end}
                percentage={mission.percentage}
                sprints={mission.sprints}
                menu={menu}
                onDropdownClick={() => setSelectId(mission.id)}
                onClick={() => {
                  dispatch(selectMission(mission));
                  setDefaultActiveKey('1');
                }}
                onSprintsClick={() => {
                  dispatch(selectMission(mission));
                  setDefaultActiveKey('5');
                }}
              />
            ))}
          </div>
          <Pagination
            total={allMissionList?.length}
            current={currentPage}
            pageSize={pageLimit}
            onChange={updatePage}
            hideOnSinglePage={false}
          />

          <MemberModal
            title='Add Member'
            visible={memberData.visible}
            onOk={handleOk}
            onCancel={handleCancel}
            typeEntity={typeEntity}
            id={selectId}
            data={memberData.data}
            emailChange={emailChange}
            permissionChange={permissionChange}
          />
          <Modal
            title='New Project'
            visible={visibleCreateModal}
            onCancel={() => actionModal()}
            footer={false}
            className='mission-modal'
            maskClosable={false}>
            <form onSubmit={handleSubmit(saveMission)}>
              <Row gutter={10}>
                <Col span={12} className='mb-10'>
                  <p>Name of Project</p>
                  <input
                    type='text'
                    className='input-class'
                    placeholder='Problem you are trying to solve'
                    {...register('name')}
                  />
                </Col>
                <Col span={12} className='mb-10'>
                <p>Tag</p>
                  <TagSelect 
                    id={`${getValues('id')}-${tagRefreshCounter}`}
                    tagsToBeRemoved={tagsToBeRemoved}
                    setTagsToBeRemoved={setTagsToBeRemoved}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <p>Start Date</p>
                  <Controller
                    name='start'
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                      <DatePicker
                        {...field}
                        placeholderText='Select date'
                        onChange={(date) => field.onChange(date)}
                        value={field.value ? moment(field.value) : ''}
                        format='MM-DD-YYYY'
                      />
                    )}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <p>End Date</p>
                  <Controller
                    name='end'
                    control={control}
                    rules={{required: true}}
                    render={({field}) => (
                      <DatePicker
                        {...field}
                        placeholderText='Select date'
                        onChange={(date) => field.onChange(date)}
                        value={field.value ? moment(field.value) : ''}
                        format='MM-DD-YYYY'
                      />
                    )}
                  />
                </Col>

                <Col span={24}>
                  <p className='my-15'>
                    I am working on this problem to improve:
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className='flex items-center'>
                    <input type='checkbox' {...register('quality')} />
                    <span className='ml-5'>Quality</span>
                  </div>
                  <div className='flex items-center'>
                    <input
                      type='checkbox'
                      {...register('patient_experience')}
                    />
                    <span className='ml-5'>Patient Experience</span>
                  </div>
                  <div className='flex items-center'>
                    <input type='checkbox' {...register('org_reputation')} />
                    <span className='ml-5'>Organizational Reputation</span>
                  </div>
                  <div className='flex items-center'>
                    <input type='checkbox' {...register('provider_rev')} />
                    <span className='ml-5'>Provider Revenue</span>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className='flex items-center'>
                    <input type='checkbox' {...register('patient_safety')} />
                    <span className='ml-5'>Patient Safety</span>
                  </div>
                  <div className='flex items-center'>
                    <input type='checkbox' {...register('cost_of_care')} />
                    <span className='ml-5'>Cost of Care</span>
                  </div>
                  <div className='flex items-center'>
                    <input type='checkbox' {...register('star_rating')} />
                    <span className='ml-5'>STAR Rating</span>
                  </div>
                  <div className='flex items-center'>
                    <input
                      type='checkbox'
                      checked={otherOption}
                      onChange={() => setOtherOption((prev) => !prev)}
                    />
                    <span className='ml-5'>Other</span>
                    <input
                      className='input-class ml-5'
                      type='text'
                      placeholder='Please Specify'
                      {...register('other_problem')}
                      disabled={!otherOption}
                    />
                  </div>
                </Col>
                <Col span={24} className='text-center mt-15'>
                  <button
                    type='button'
                    className='modal-delete-btn'
                    onClick={() => actionModal()}>
                    Cancel
                  </button>
                  <button type='submit' className='modal-dont-delete ml-10'>
                    Save
                  </button>
                </Col>
              </Row>
            </form>
          </Modal>
        </div>
      ) : (
        <div>
          <div className='flex flex-row justify-between'>
            <h2>Project: {dataMissionSelect?.name}</h2>
          </div>
          {sprint.name && (
            <h4>
              {t('commons.intervention')} {sprint.uid}: {sprint.name}
            </h4>
          )}
          <StatusAndTrend />
          <div
            className='my-15 pointer'
            onClick={() => dispatch(resetMission())}>
            <p>
              <b>Return to Projects</b>
            </p>
          </div>
          <Tabs
            type='card'
            defaultActiveKey={defaultActiveKey}
            onChange={() => dispatch(cleanSprint())}
            onTabClick={(e) => {
              if (e == '5') {
                dispatch(cleanSprint());
              }
            }}>
            <TabPane tab='Overview' key='1'>
              <Overview />
            </TabPane>
            {/* 		<TabPane tab="Mission Team" key="2">
								<Team />
							</TabPane> */}
            <TabPane tab='Key Drivers' key='3'>
              <KeyDrivers />
            </TabPane>
            <TabPane tab='Smart Solutions Search' key='4'>
              <SmartSolutions />
            </TabPane>
            <TabPane tab={t('commons.interventions')} key='5'>
              <Sprints />
            </TabPane>
            {/* <TabPane tab='MVOP' key='6'>
              <MVOP />
            </TabPane>
             */}
            {/*
							<TabPane tab="Performance" key="7">
								<p>asd</p>
										</TabPane>*/}
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default Mission;
