export const ROUTES = {
  INDEX: '/',
  SEARCH: '/search',
  CONTACT: '/contact-us',
  IFRAME_TEAMS: '/iframe/teams',
  LOGIN: '/:client/login',
  PAT: '/pat/:id',
  MAINFRAME: '/',
  DASHBOARD: '/admin-dashboard',
  NETWORKS: '/networks',
  TEAMS: '/teams',
  TAGS: '/tags',
  TAG_DETAIL: '/tag/:id',
  INTEL: {
    DEFAULT: '/:organizationId/intel',
    MA_ACTION_PLANS: '/:organizationId/intel/ma-action-plans',
    OBSERVATIONS: '/:organizationId/intel/observations',
    DEMOGRAPHICS: '/:organizationId/intel/demographics',
    DOCUMENTS: '/:organizationId/intel/documents',
    PRACTICE_WORKFLOWS: '/:organizationId/intel/practice-workflows',
  },
  GROUPS: '/groups',
  MEETINGS: '/meetings',
  TOOLBOX: '/toolbox',
  PROFILE: '/profile',
  COMMENTS: '/comments/:id',
  REFLECTIONS: '/reflections/:id',
  PREPARING: '/preparing/:id',
  CREATE_MISSION: '/:id/create-mission',
  VIEW_ORGANISATION_DETAILS: '/:id/view-details',
  VIEW_MISSION_DETAILS: '/mission-details/:id',
  VIEW_NETWORK_GROUP_DETAILS: '/network-group-details/:id',
  VIEW_SPRINT: '/view-sprint/:id',
  VIEW_SPRINT_ORGANIZATION: '/view-sprint-organization/:id',
  LOAD_MISSION: '/load-mission',
  ASK_WIZ: '/ask-wiz',
  EVOLUTION: '/evolution',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/:client/set_password/:uid/:token',
  VIEW_MSOP_REPORT: '/:id/msop-report/',
  STAND_ALONE_MSOP_REPORT: '/msop-report/',
  SPRINT_DETAILS: '/:id/sprint-details/:sprintId',
  ORGANIZATION_SPRINT_DETAILS: '/:id/organization-sprint-details/:sprintId',
  ACTIVATION_LINK: '/activation-link',
  ORGANISATION_INSIGHTS: '/:id/organisation-insights',
  SLATE_EXAMPLE: '/slate-example',
  ACCOUNT_INSIGHTS: '/account-insights',
  SIMILAR_MISSIONS: '/similar-missions',
  COACH_DASHBOARD: '/coach-dashboard',
  SPRINT_HUDDLES: '/:id/sprint-huddles/:sprintId',
  SPRINT_IN_ACTION: '/:id/sprint-in-action/:sprintId',
  ORGANIZATION_SPRINT_HUDDLES: '/:id/organization-sprint-huddles/:sprintId',
  ORGANIZATION_SPRINT_IN_ACTION: '/:id/organization-sprint-in-action/:sprintId',
  SHARED_PAT: '/shared-pat/:token',
};
