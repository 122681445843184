import React from 'react';
import './StatusAndTrend.scss';
import { useDispatch, useSelector } from 'react-redux';
import flat from '../../../../../../../../Assets/flat.svg';
import up from '../../../../../../../../Assets/up.png';
import down from '../../../../../../../../Assets/down.png';
import { Select, Radio } from 'antd';
import { updateMission } from '../../../../../../../../ReduxV2/state';
import { patchMissions } from '../../../../../../../../Services/Mission.service';
import { getNotification } from '../../../../../../../../Components/GetNotification';

/**
 * Updates the mission data with the given key and value.
 *
 * @param {string} key - The key to update.
 * @param {any} value - The value to update.
 * @return {void}
 */
const StatusAndTrend = () => {
  const dispatch = useDispatch();

  const dataMissionSelect = useSelector((state) => {
    return state.mission.value;
  });

  const updateMissionData = async (key, value) => {
    try {
      await patchMissions({ id: dataMissionSelect?.id, [key]: value })
        .call;
      dispatch(updateMission({ key, value }));
      getNotification('success', {
        header: 'success',
        body: 'Project updated successfully',
      });
    } catch (error) { }
  };

  return (
    <div className='StatusAndTrend'>
      <div className='flex justify-beetween'>
        <div className='flex-vertical'>
          <p className='mr-5'>Status:</p>
          <Select
            className='StatusAndTrend-select'
            optionFilterProp='children'
            bordered={false}
            value={dataMissionSelect.status}
            onChange={(status) => updateMissionData('status', status)}
            options={[
              { label: 'In progress', value: 'IN_PROGRESS' },
              { label: 'Completed', value: 'COMPLETED' },
            ]}
          />
        </div>
        <div className='flex-vertical'>
          <p className='mr-5'>Trend:</p>
          <Radio.Group
            value={dataMissionSelect.trend}
            onChange={({ target }) => updateMissionData('trend', target.value)}>
            <Radio.Button value='UP'>
              <img className='ProjectsLIst-img' src={up} alt='up' />
            </Radio.Button>
            <Radio.Button value='FLAT'>
              <img className='ProjectsLIst-img' src={flat} alt='flat' />
            </Radio.Button>
            <Radio.Button value='DOWN'>
              <img className='ProjectsLIst-img' src={down} alt='down' />
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default StatusAndTrend;
