import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import jwt from 'jsonwebtoken';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {GetLocalStorage} from '../../utils/localStorageOperations';
import {postRefresh} from '../../Services/Login.service';

import { useTranslation } from "react-i18next"

const PrivateRoute = ({component: Component, ...rest}) => {
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useHistory();

  const client = GetLocalStorage('clientName') || 'blue-agilis';

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate.push(`/${client}/login`);
  };

  const refreshToken = async () => {
    try {
      const res = await postRefresh(localStorage.getItem('refreshToken')).call;
      localStorage.setItem('idToken', res.data.access);
      setIsTokenExpired(false);
    } catch (error) {
    }
  };

  // i18n translation hook
  const [t] = useTranslation()

  useEffect(() => {
    const checkTokenStatus = () => {
      const token = GetLocalStorage('idToken');

      const decodedToken = jwt.decode(token);
      const expirationDate = decodedToken.exp * 1000;

      const isExpired = Date.now() >= expirationDate;

      setIsTokenExpired(isExpired);
    };

    const interval = setInterval(checkTokenStatus, 240000);

    return () => clearInterval(interval);
  }, []);

  if (!GetLocalStorage('idToken')) return <Redirect to={`/${client}/login`} />;
  else
    return (
      <>
        <Route {...rest}>{Component}</Route>
        <Modal
          visible={isTokenExpired}
          footer={false}
          maskClosable={false}
          bodyStyle={{overflowY: 'scroll'}}>
          <div className='w-100 h-250px center-flex-align items-center'>
            <div>
              <h3 className='text-center'>{t("Token_expired.Title")}</h3>
              <p className='text-center'>
                {t("Token_expired.Message")}
              </p>
              <div className='w-80 flex justify-beetween mt-15 m-auto'>
                <button
                  type='button'
                  className='modal-delete-btn'
                  onClick={logOut}>
                  {t("Button.Log_out")}
                </button>
                <button
                  type='button'
                  className='modal-dont-delete'
                  onClick={refreshToken}>
                  {t("Button.Refresh_token")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
};
export default PrivateRoute;
