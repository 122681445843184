import _ from 'lodash';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getSprint} from 'Services/Sprints.service';
import {
  getSprintWorkflow,
  getSprintWorkflowRoles,
  postSprintWorkflow,
} from 'Services/SprintWorkflow.service';
import {SprintDetail} from 'types/Sprint';
import {
  SprintWorkflow,
  SprintWorkflowRole,
  SprintWorkflowStep,
  SprintWorkflowSubstep,
} from 'types/SprintWorkflow';

const MESSAGES = {
  STEP_CREATED: 'Step created',
  STEP_DELETED: 'Step deleted',
  STEP_UPDATED: 'Step updated',
  STEPS_REORDERED: 'Steps reordered',
  SUBSTEP_CREATED: 'Substep created',
  SUBSTEP_DELETED: 'Substep deleted',
  SUBSTEP_UPDATED: 'Substep updated',
  SUBSTEPS_REORDERED: 'Substeps reordered',
  WORKFLOW_CREATED: 'Workflow created',
  WORKFLOW_UPDATED: 'Workflow updated',
  WORKFLOW_VERSION_SELECTED: 'Workflow version selected',
};

type FlattenedRoles = Array<{
  role: SprintWorkflowRole;
}>;

type Option = {
  value: number;
  label: string;
};

type OptGroups = Array<{
  label: string;
  options: Array<Option>;
}>;

type SprintState = {
  value: number;
  sprintDetail: {
    data: SprintDetail;
    error: string | null;
    status: 'idle' | 'loading' | 'rejected';
  };
  sprintWorkflow: {
    lastSavedData: SprintWorkflow;
    data: SprintWorkflow;
    isEdited: boolean;
    error: string | null;
    success: string | null;
    status: 'idle' | 'loading' | 'rejected';
  };
  sprintWorkflowRoles: SprintWorkflowRole[];
  sprintWorkflowRoleOptions: OptGroups;
  sprintWorkflowVersions: SprintWorkflow[];
};

const generateUniqueId = (): number => {
  const uniqueId = Math.round(
    parseInt(`${new Date().getTime()}${Math.random()}`, 10)
  );
  return uniqueId;
};

const renumerateSteps = (steps: SprintWorkflowStep[]) => {
  const newSteps = _.map(steps, (step, index) => {
    return {
      ...step,
      order: index + 1,
    };
  });
  return newSteps;
};

const reorderStepsInState = (
  steps: SprintWorkflowStep[],
  stepId: number,
  endOrder: number
) => {
  const newSteps = _.cloneDeep(steps);
  const stepIndex = _.findIndex(steps, {id: stepId});
  if (stepIndex !== -1) {
    const removedStep = newSteps.splice(stepIndex, 1)[0];
    newSteps.splice(endOrder, 0, removedStep);
    return renumerateSteps(newSteps);
  }
  return newSteps;
};

const addStepToState = (state: SprintState) => {
  const newState = {...state};
  // find the highest order number in state
  const highestOrder =
    _.maxBy(newState.sprintWorkflow.data.steps, 'order')?.order ?? 0;

  /**
   * Because the UI for adding substeps relies on an existing substep,
   * all new steps must have at least one substep.
   */

  const newSubstep = {
    id: generateUniqueId(),
    name: '',
    order: 1,
    role_responsible: {} as SprintWorkflowRole,
  } as SprintWorkflowSubstep;

  const newStep = {
    id: generateUniqueId(),
    notes: '',
    name: '',
    order: highestOrder + 1,
    sub_steps: [newSubstep] as SprintWorkflowSubstep[],
  } as SprintWorkflowStep;

  const newWorkflow = {
    ...newState.sprintWorkflow.data,
    steps: [...newState.sprintWorkflow.data.steps, newStep],
  };

  newState.sprintWorkflow = {
    ...newState.sprintWorkflow,
    status: 'idle',
    success: MESSAGES.STEP_CREATED,
    isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
    data: newWorkflow,
  };
  return newState;
};

const removeStepFromState = (state: SprintState, stepId: number) => {
  const newState = {...state};
  const newSteps = renumerateSteps(
    _.filter(newState.sprintWorkflow.data.steps, (step) => step.id !== stepId)
  );
  const newWorkflow = {
    ...newState.sprintWorkflow.data,
    steps: newSteps,
  };

  newState.sprintWorkflow = {
    ...newState.sprintWorkflow,
    status: 'idle',
    success: MESSAGES.STEP_DELETED,
    isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
    data: newWorkflow,
  };
  return newState;
};

const updateStepinState = (
  state: SprintState,
  {
    stepId,
    name,
    order,
    notes,
  }: {stepId: number; name?: string; order?: number; notes?: string}
) => {
  const newState = {...state};
  // Find the step to update
  const updatedSteps = newState.sprintWorkflow.data.steps.map((step) => {
    if (step.id === stepId) {
      // Update the name and order if provided
      return {
        ...step,
        name: name !== undefined ? name : step.name,
        order: order !== undefined ? order : step.order,
        notes: notes !== undefined ? notes : step.notes,
      };
    }
    return step;
  });

  const newWorkflow = {
    ...newState.sprintWorkflow.data,
    steps: updatedSteps,
  };

  newState.sprintWorkflow = {
    ...newState.sprintWorkflow,
    status: 'idle',
    success: '',
    isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
    data: newWorkflow,
  };

  return newState;
};

const addSubstepToState = (
  state: SprintState,
  {sw_step, index}: {sw_step: number; index: number}
) => {
  const newSubstep = {
    id: generateUniqueId(),
    name: '',
    order: 0,
    role_responsible: {} as SprintWorkflowRole,
    notes: '',
  } as SprintWorkflowSubstep;
  const newState = {...state};
  const step = _.find(newState.sprintWorkflow.data.steps, {id: sw_step});
  if (step) {
    const newStep = _.cloneDeep(step);
    const substeps = _.orderBy(newStep.sub_steps, ['order'], ['asc']);
    substeps.splice(index + 1, 0, newSubstep);
    _.forEach(substeps, (item, index) => {
      item.order = index + 1;
    });
    newStep.sub_steps = substeps;

    const newWorkflow = {
      ...newState.sprintWorkflow.data,
      steps: _.map(newState.sprintWorkflow.data.steps, (step) => {
        if (step.id === sw_step) {
          return newStep;
        }
        return step;
      }),
    };

    newState.sprintWorkflow = {
      ...newState.sprintWorkflow,
      status: 'idle',
      success: MESSAGES.SUBSTEP_CREATED,
      isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
      data: newWorkflow,
    };
  }
  return newState;
};

const updateSubstepInStore = (
  state: SprintState,
  {
    sw_step,
    sw_substep,
    name,
    role_responsible,
  }: {
    sw_step: number;
    sw_substep: number;
    name?: string;
    role_responsible?: number;
  }
) => {
  const newState = {...state};
  const step = _.find(newState.sprintWorkflow.data.steps, {id: sw_step});
  if (step) {
    const newStep = _.cloneDeep(step);
    const substeps = _.map(newStep.sub_steps, (substep) => {
      if (substep.id === sw_substep) {
        /**
         * If role_responsible is defined, find the sprintWorkflowRole with that id and assign it.
         */
        let role_responsible_data = {} as SprintWorkflowRole;
        if (role_responsible !== undefined) {
          role_responsible_data = _.find(newState.sprintWorkflowRoles, {
            id: role_responsible,
          }) as SprintWorkflowRole;
        }

        return {
          ...substep,
          name: name !== undefined ? name : substep.name,
          role_responsible: role_responsible_data,
        };
      }
      return substep;
    });
    newStep.sub_steps = substeps;

    const newWorkflow = {
      ...newState.sprintWorkflow.data,
      steps: _.map(newState.sprintWorkflow.data.steps, (step) => {
        if (step.id === sw_step) {
          return newStep;
        }
        return step;
      }),
    };

    newState.sprintWorkflow = {
      ...newState.sprintWorkflow,
      status: 'idle',
      isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
      data: newWorkflow,
    };
  }
  return newState;
};

const moveSubstepInStore = (
  state: SprintState,
  {
    sw_step,
    sw_substep,
    endOrder,
  }: {sw_step: number; sw_substep: number; endOrder: number}
) => {
  const newState = {...state};
  const step = _.find(newState.sprintWorkflow.data.steps, {id: sw_step});
  if (step) {
    const newStep = _.cloneDeep(step);
    const substeps = _.orderBy(newStep.sub_steps, ['order'], ['asc']);


    const substepIndex = _.findIndex(substeps, {id: sw_substep});
    if (substepIndex !== -1) {
      const removedSubstep = substeps.splice(substepIndex, 1)[0];
      substeps.splice(endOrder, 0, removedSubstep);
      _.forEach(substeps, (item, index) => {
        item.order = index + 1;
      });
    }

    newStep.sub_steps = substeps;
    const newWorkflow = {
      ...newState.sprintWorkflow.data,
      steps: _.map(newState.sprintWorkflow.data.steps, (step) => {
        if (step.id === sw_step) {
          return newStep;
        }
        return step;
      }),
    };

    newState.sprintWorkflow = {
      ...newState.sprintWorkflow,
      isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
      status: 'idle',
      data: newWorkflow,
    };
  }
  return newState;
};

const removeSubstepFromStore = (
  state: SprintState,
  {sw_step, sw_substep}: {sw_step: number; sw_substep: number}
) => {
  const newState = {...state};
  const step = _.find(newState.sprintWorkflow.data.steps, {id: sw_step});
  if (step) {
    const newStep = _.cloneDeep(step);
    const substeps = _.filter(
      newStep.sub_steps,
      (substep) => substep.id !== sw_substep
    );
    newStep.sub_steps = substeps;

    const newWorkflow = {
      ...newState.sprintWorkflow.data,
      steps: _.map(newState.sprintWorkflow.data.steps, (step) => {
        if (step.id === sw_step) {
          return newStep;
        }
        return step;
      }),
    };

    newState.sprintWorkflow = {
      ...newState.sprintWorkflow,
      status: 'idle',
      isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
      success: MESSAGES.SUBSTEP_DELETED,
      data: newWorkflow,
    };
  }
  return newState;
};

const updateWorkflowInStore = (
  state: SprintState,
  {name, summary}: {name?: string; summary?: string}
) => {
  const newState = {...state};
  const newWorkflow = {
    ...newState.sprintWorkflow.data,
    name: name !== undefined ? name : newState.sprintWorkflow.data.name,
    summary:
      summary !== undefined ? summary : newState.sprintWorkflow.data.summary,
  };
  newState.sprintWorkflow = {
    ...newState.sprintWorkflow,
    isEdited: !_.isEqual(newWorkflow, newState.sprintWorkflow.lastSavedData),
    data: newWorkflow,
  };
  return newState;
};

const selectWorkflowFromVersionHistory = (
  state: SprintState,
  {workflow_id}: {workflow_id: number}
) => {
  const newState = {...state};
  const selectedWorkflow = _.find(newState.sprintWorkflowVersions, {
    id: workflow_id,
  });
  if (selectedWorkflow) {
    const successMessage = selectedWorkflow?.name
      ? `${MESSAGES.WORKFLOW_VERSION_SELECTED}: ${selectedWorkflow.name}`
      : MESSAGES.WORKFLOW_VERSION_SELECTED;
    newState.sprintWorkflow = {
      ...newState.sprintWorkflow,
      success: successMessage,
      isEdited: !_.isEqual(
        selectedWorkflow,
        newState.sprintWorkflow.lastSavedData
      ),
      data: selectedWorkflow,
    };
  }
  return newState;
};

const cleanWorkflowInStore = (state: SprintState) => {
  const newState = {...state};
  newState.sprintWorkflow = {
    ...newState.sprintWorkflow,
    error: '',
    success: '',
    data: {
      id: generateUniqueId(),
      name: '',
      summary: '',
      mission_sprint: newState.value,
      steps: [],
    },
  };
  return newState;
};

export const getSprintById = createAsyncThunk(
  'sprint/getSprintById',
  async (sprintId: number) => {
    try {
      const response = await getSprint(sprintId).call;
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const createSprintWorkflow = createAsyncThunk(
  'sprint/createSprintWorkflow',
  async (__, {getState}) => {
    try {
      // do logic to get the data from the store
      //@ts-ignore
      const workflowState: any = getState()?.sprint?.sprintWorkflow.data;
      let workflowPayload = _.cloneDeep(
        workflowState ?? ({} as SprintWorkflow)
      );

      // remove id and created from the workflow
      delete workflowPayload.id;
      delete workflowPayload.created;

      // remove id and sprint_workflow from steps
      workflowPayload.steps = _.map(workflowPayload.steps, (step) => {
        if (step.hasOwnProperty('id')) {
          delete step.id;
        }
        if (step.hasOwnProperty('sprint_workflow')) {
          delete step.sprint_workflow;
        }

        // for each step's sub_steps, the role responsible should just be the id
        step.sub_steps = _.map(step.sub_steps, (substep) => {
          if (substep.hasOwnProperty('id')) {
            delete substep.id;
          }
          if (substep.hasOwnProperty('sw_step')) {
            delete substep.sw_step;
          }

          if (substep.hasOwnProperty('role_responsible')) {
            return {
              ...substep,
              role_responsible: substep?.role_responsible?.id ?? null,
            };
          }

          return substep;
        });

        return step;
      });

      const response = await postSprintWorkflow(workflowPayload).call;
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

//
export const getSprintWorkflowsBySprintId = createAsyncThunk(
  'sprint/getSprintWorkflowsBySprintId',
  async (sprintId: number) => {
    try {
      const response = await getSprintWorkflow(sprintId).call;
      return response?.data?.results; // most recent workflow by date
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchSprintWorkflowRoles = createAsyncThunk(
  'sprint/fetchSprintWorkflowRoles',
  async () => {
    try {
      const response = await getSprintWorkflowRoles().call;
      if (response?.data) {
        const roles: FlattenedRoles = _.flatten(_.values(response.data));

        // Convert from RoleData to OptGroups
        const optGroups: OptGroups = [];
        _.forEach(response.data, (roles, category) => {
          const options = _.map(roles, ({role}) => {
            return {
              value: role.id,
              label: role.name,
            };
          });
          optGroups.push({
            label: category,
            options,
          });
        });
        return {
          data: roles.map((item) => item.role),
          options: optGroups,
        };
      }
    } catch (error) {
      console.error(error);
    }
  }
);

const setWorkflowStatus = (
  state: SprintState,
  status: 'idle' | 'loading' | 'rejected'
) => {
  state.sprintWorkflow.status = status;
};

const initialState: SprintState = {
  value: 0,
  sprintDetail: {
    status: 'idle',
    error: null,
    data: {} as SprintDetail,
  },
  sprintWorkflow: {
    status: 'idle',
    isEdited: false,
    error: null,
    success: null,
    data: {} as SprintWorkflow,
    lastSavedData: {} as SprintWorkflow,
  },
  sprintWorkflowRoles: {} as any,
  sprintWorkflowRoleOptions: [] as OptGroups,
  sprintWorkflowVersions: [] as SprintWorkflow[],
};

export const sprintSlice = createSlice({
  name: 'sprint',
  initialState,
  reducers: {
    revertSprint: (state) => {
      return {
        ...state,
        sprintWorkflow: {
          ...state.sprintWorkflow,
          isEdited: false,
          data: state.sprintWorkflow.lastSavedData,
        },
      };
    },
    addStep: (state) => {
      return addStepToState(state);
    },
    updateStep: (
      state,
      {
        payload,
      }: {
        payload: {stepId: number; name?: string; order?: number; notes: string};
      }
    ) => {
      if (payload) {
        return updateStepinState(state, payload);
      }
    },
    moveStep: (
      state,
      {payload}: {payload: {stepId: number; endOrder: number}}
    ) => {
      if (payload) {
        const newState = {...state};
        const newSteps = reorderStepsInState(
          newState.sprintWorkflow.data.steps,
          payload.stepId,
          payload.endOrder
        );
        const newWorkflow = {
          ...newState.sprintWorkflow.data,
          steps: newSteps,
        };
        newState.sprintWorkflow = {
          ...newState.sprintWorkflow,
          isEdited: !_.isEqual(
            newWorkflow,
            newState.sprintWorkflow.lastSavedData
          ),
          data: newWorkflow,
        };
        return newState;
      }
    },
    removeStep: (state, {payload}: {payload: number}) => {
      if (payload) {
        return removeStepFromState(state, payload);
      }
    },
    addSubstepAfter: (
      state,
      {payload}: {payload: {sw_step: number; index: number}}
    ) => {
      if (payload) {
        return addSubstepToState(state, payload);
      }
    },
    updateSubstep: (
      state,
      {
        payload,
      }: {
        payload: {
          sw_step: number;
          sw_substep: number;
          name?: string;
          role_responsible?: number;
        };
      }
    ) => {
      if (payload) {
        return updateSubstepInStore(state, payload);
      }
    },
    moveSubstep: (
      state,
      {
        payload,
      }: {payload: {sw_step: number; sw_substep: number; endOrder: number}}
    ) => {
      if (payload) {
        return moveSubstepInStore(state, payload);
      }
    },
    removeSubstep: (
      state,
      {payload}: {payload: {sw_step: number; sw_substep: number}}
    ) => {
      if (payload) {
        return removeSubstepFromStore(state, payload);
      }
    },
    selectWorkflow: (state, {payload}: {payload: {workflow_id: number}}) => {
      if (payload) {
        return selectWorkflowFromVersionHistory(state, payload);
      }
    },
    updateWorkflow: (
      state,
      {payload}: {payload: {name?: string; summary?: string}}
    ) => {
      if (payload) {
        return updateWorkflowInStore(state, payload);
      }
    },
    cleanWorkflow: (state) => {
      return cleanWorkflowInStore(state);
    },
    selectSprint: (state, {payload}) => {
      return {
        ...state,
        value: payload.id,
      };
    },
    cleanSprint: (state) => {
      return {
        ...initialState,
      };
    },
    cleanSprintWorkflowError: (state) => {
      return {
        ...state,
        sprintWorkflow: {
          ...state.sprintWorkflow,
          error: null,
        },
      };
    },
    cleanSprintWorkflowSuccess: (state) => {
      return {
        ...state,
        sprintWorkflow: {
          ...state.sprintWorkflow,
          success: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSprintById.pending, (state) => {
        state.sprintDetail.status = 'loading';
      })
      .addCase(getSprintById.fulfilled, (state, {payload}) => {
        state.sprintDetail.status = 'idle';
        if (payload) {
          state.sprintDetail.data = payload;
        }
      })
      .addCase(getSprintById.rejected, (state, action) => {
        state.sprintDetail.status = 'rejected';
        state.sprintDetail.error = action.error.message ?? null;
      });

    builder
      .addCase(getSprintWorkflowsBySprintId.pending, (state) => {
        setWorkflowStatus(state, 'loading');
      })
      .addCase(getSprintWorkflowsBySprintId.fulfilled, (state, action) => {
        setWorkflowStatus(state, 'idle');
        if (!action?.payload || _.isEmpty(action?.payload)) {
          state.sprintWorkflow.error = 'No workflow found';
        } else {
          state.sprintWorkflow.data = action.payload[0];
          state.sprintWorkflow.lastSavedData = action.payload[0];
          state.sprintWorkflowVersions = action.payload;
        }
      })
      .addCase(getSprintWorkflowsBySprintId.rejected, (state, action) => {
        setWorkflowStatus(state, 'rejected');
        state.sprintWorkflow.error = action.error.message ?? null;
      });

    builder
      .addCase(fetchSprintWorkflowRoles.pending, (state) => {
        setWorkflowStatus(state, 'loading');
      })
      .addCase(fetchSprintWorkflowRoles.fulfilled, (state, {payload}) => {
        const newState = {...state};
        if (payload) {
          newState.sprintWorkflow = {
            ...newState.sprintWorkflow,
            status: 'idle',
          };
          newState.sprintWorkflowRoles = (payload.data ??
            []) as SprintWorkflowRole[];
          newState.sprintWorkflowRoleOptions = payload.options as OptGroups;
        }
        return newState;
      });

    builder
      .addCase(createSprintWorkflow.pending, (state) => {
        setWorkflowStatus(state, 'loading');
      })
      .addCase(createSprintWorkflow.fulfilled, (state, {payload}) => {
        const newState = {...state};
        if (payload) {
          newState.sprintWorkflow = {
            ...newState.sprintWorkflow,
            status: 'idle',
            isEdited: false,
            success: MESSAGES.WORKFLOW_CREATED,
          };
          newState.sprintWorkflowVersions = [
            ...newState.sprintWorkflowVersions,
            payload,
          ];
        }
        return newState;
      })
      .addCase(createSprintWorkflow.rejected, (state, action) => {
        setWorkflowStatus(state, 'rejected');
        state.sprintWorkflow.error = action.error.message ?? null;
      });
  },
});

export const {
  addStep,
  updateStep,
  moveStep,
  removeStep,
  addSubstepAfter,
  updateSubstep,
  moveSubstep,
  removeSubstep,
  updateWorkflow,
  selectWorkflow,
  cleanWorkflow,
  revertSprint,
  selectSprint,
  cleanSprint,
  cleanSprintWorkflowSuccess,
  cleanSprintWorkflowError,
} = sprintSlice.actions;
