import React from 'react';
import {Button, Modal} from 'antd';
import {useState} from 'react';
import './SmartSolutionChatbot.scss'; // Assuming you have a stylesheet
import smartSolution from 'Assets/icons/smartSolution.svg';
import arrowSmartSolution from 'Assets/icons/arrowSmartSolution.png';
/**
 * Renders the SmartSolutionChatbot.
 *
 * @return {ReactElement} The rendered Dashboard layout.
 */

const SmartSolutionChatbot = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('https://coach.blueagilis.com/');

  const showModal = () => {
    setIsModalVisible(true);
    setIframeSrc('https://coach.blueagilis.com/'); // Set iframe src when showing modal
  };

  const handleCancel = () => {
    setIframeSrc(''); // Reset iframe src to blank to force refresh
    setTimeout(() => {
      setIsModalVisible(false);
    }, 0);
  };

  const handleMinimize = () => {
    setIsModalVisible(false);
    setIsMinimized(true);
  };

  const handleRestore = () => {
    setIsModalVisible(true);
    setIsMinimized(false);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleMinimize}
        onCancel={handleMinimize}
        footer={null}
        className='solution-modal'
        centered
        width='50vw'
        bodyStyle={{padding: '1rem', height: '80vh', overflow: 'hidden'}}
        closeIcon={null}
      >
        <div className='custom-modal-header'>
          <div className='minimize-icon' onClick={handleMinimize}>
            -
          </div>
          <div className='close-icon' onClick={handleCancel}>
            x
          </div>
        </div>
        {iframeSrc && (
          <iframe
            src={iframeSrc}
            title='Solutions'
            className='solution-iframe'></iframe>
        )}
      </Modal>
      <Button
        type='primary'
        shape='round'
        size='large'
        style={{
          background: '#08161F',
          position: 'fixed',
          bottom: '60px',
          right: '40px',
          zIndex: 1000,
        }}
        onClick={isMinimized ? handleRestore : showModal}>
        {isMinimized ? (
          <div className='flex flex-row gap-1'>
            <img src={smartSolution} alt='SolutionsIcon' />
            Solutions
            <img src={arrowSmartSolution} alt='SolutionsArrowIcon' />
          </div>
        ) : (
          <img src={smartSolution} alt='SolutionsIcon' />
        )}
      </Button>
    </>
  );
};

export default SmartSolutionChatbot;