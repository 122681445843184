import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select, Col, Row } from 'antd';
import {createNetwork, updateNetwork} from 'ReduxV2/state';
import {useDispatch} from 'react-redux';
// import ImageUpload from 'Components/ImageUpload';
import {ImageUpload} from 'Components/atoms';
import {getNotification} from 'Components/GetNotification';
import { useTranslation } from "react-i18next"
import TagSelect from 'Components/TagSelect';
import { Tag, TagType } from 'types/Tags';
import { removeTagsForArray } from 'Services/Tag.service';

type ModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  afterClose?: () => void;
  network?: any;
  groups?: any[];
};

const filterOption = (input: string, option?: {label: string; value: string}) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const AddOrEditNetworkModal: React.FC<ModalProps> = ({
  isOpen,
  onCancel,
  onOk,
  afterClose,
  network,
  groups,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [UploadedImage, setUploadedImage] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState((network?.tags ?? []).map((t:Tag) => t.name));
  const [tagsToBeRemoved, setTagsToBeRemoved] = useState([]);
  const [tagRefreshCounter, setTagRefreshCounter] = useState(0);

  // i18n translation hook
  const [t] = useTranslation()

  //@todo GET returns a url, POST or PATCH wants a blob.
  //@see submitOrganisationAction in AddOrganisation/index.js
  const onFinish = async (values: any) => {
    if (network) {
      //group_id of 1 is the default (no group)
      const payload = {...values, id: network.id};
      payload.group_id = payload.group_id ?? null;

      payload.tags = selectedTags;

      if (UploadedImage) {
        payload.profile_image_url = UploadedImage;
      } else {
        delete payload.profile_image_url;
      }

      //remove empty values from payload
      Object.keys(payload).forEach(
        (key) => payload[key] == null && delete payload[key]
      );

      if(tagsToBeRemoved?.length > 0) {
        removeTagsForArray(tagsToBeRemoved, TagType.NETWORK, network?.id);
      }
 
      setTagRefreshCounter(prev => prev + 1);
      await dispatch(updateNetwork(payload));
    } else {
      const payload = {...values, group_id: values.group_id ?? null};

      payload.tags = selectedTags;

      if (UploadedImage) {
        payload.profile_image_url = UploadedImage;
      } else {
        delete payload.profile_image_url;
      }
      await dispatch(createNetwork(payload));
    }

    onOk();
  };

  const getGroupIdByName = (name: string) => {
    const group = groups?.find((group: any) => group.label === name);
    return group?.value;
  };

  const initialGroup = getGroupIdByName(network?.group_name ?? '');
  const profileImageUrl = network?.profile_image_url ?? '';

  useEffect(() => {
    if (network) {
      setSelectedTags(network?.tags?.map((t:Tag) => t?.name))
      form.setFieldsValue({...network, group_id: initialGroup});
    } else {
      setUploadedImage(null);
      form.resetFields();
    }
  }, [network, form]);

  useEffect(() => {
    form.setFieldsValue({...network, tags: selectedTags});
  }, [selectedTags]);

  return (
    <Modal
      wrapClassName='rounded-2xl'
      open={isOpen}
      width={1000}
      afterClose={() => {
        if (afterClose) {
          afterClose();
        }
        form.resetFields();
      }}
      onCancel={onCancel}
      footer={false}>
      <div className='grid gap-4 mt-4'>
        <h1 className='text-4xl leading-tight px-8'>
          {network ? 'Edit Network' : 'Add Network'}
        </h1>
        <Form
          layout='vertical'
          className='grid px-8'
          form={form}
          onFinish={onFinish}
          initialValues={{...network, group_id: initialGroup}}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              e.preventDefault();
              form.submit();
            }
          }}>
          <div className='grid grid-cols-2 gap-x-4'>
            <Form.Item
              name='name'
              label='Network Title'
              rules={[
                {required: true, message: 'Network Title is required.'},
                {max: 60, message: 'Max 60 characters.'},
              ]}>
              <Input placeholder='Max 60 characters' />
            </Form.Item>
            <Form.Item name='point_of_contact' label='Contact Name'>
              <Input />
            </Form.Item>
            <Form.Item name='country' label='Address'>
              <Input />
            </Form.Item>
            <Form.Item
              name='contact_email'
              label='Contact Email'
              rules={[{required: true, message: 'Contact Email is required.'}]}>
              <Input type='email' />
            </Form.Item>
            <Row>
              <Col>
                <Form.Item name="tag" label="Tag">
                  <TagSelect
                    id={`${network?.id}-${tagRefreshCounter}`}
                    tagsToBeRemoved={tagsToBeRemoved}
                    setTagsToBeRemoved={setTagsToBeRemoved}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name='phone' label='Phone Number'>
              <Input type='tel' />
            </Form.Item>
            <Form.Item name='group_id' label='Group'>
              <Select
                allowClear={true}
                showSearch={true}
                options={groups}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item
              name='profile_image_url'
              className='col-span-2'
              label={`Upload a logo by clicking on 'Upload' or dragging a file onto the box.
                Note: Logo must be 1MB or less in size (.jpg, .jpeg, and .png accepted)`}>
              <ImageUpload
                onFileUpload={(file: any) => {
                  setUploadedImage(file);
                }}
                // beforeUpload={(file: any) => {
                //   const acceptedFormats = ['jpg', 'png', 'jpeg'];
                //   let extension = file?.name.split('.').pop() ?? '';
                //   if (acceptedFormats.includes(extension)) return true;
                //   else {
                //     getNotification('info', {
                //       header: '',
                //       body: `Please upload a file with extension .jpg, .jpeg
                //        or .png`,
                //     });
                //     return Upload.LIST_IGNORE;
                //   }
                // }}
                defaultFileList={
                  profileImageUrl ? [{url: profileImageUrl}] : []
                }
              />
            </Form.Item>
          </div>
          <footer className='flex justify-end pt-5 gap-2'>
            <Form.Item>
              <button
                type='button'
                className='px-4 py-2 text-base font-medium self-center'
                onClick={onCancel}>
                {t("Button.Cancel")}
              </button>
            </Form.Item>
            <Form.Item>
              <button
                type='submit'
                className='px-4 py-2 text-base font-medium bg-primary text-white rounded 
              hover:bg-primary-hover'>
                {t("Button.Save")}
              </button>
            </Form.Item>
          </footer>
        </Form>
      </div>
    </Modal>
  );
};
