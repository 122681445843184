import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Radio, Row, Select} from 'antd';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  getSprintById,
  getSprintWorkflowsBySprintId,
  fetchSprintWorkflowRoles,
} from 'ReduxV2/state';

import {
  getSprint,
  postSprintMeasures,
  postSprintPriorActionItems,
  patchSprints,
} from 'Services/Sprints.service';
import {getMemberList} from 'Services/Teams.service';
import {AUTOSAVING_DELAY} from '../../../../../../../../../../../config/constants';
import {getMissionDetail} from 'Services/Mission.service';
import {getNotification} from 'Components/GetNotification';
import {debounce} from 'lodash';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from 'Components/DebounceLoading/DebounceLoading';
import FormTable from 'Components/FormTable/FormTable';
import TextareaDebounce from 'Components/TextareaDebounce/TextareaDebounce';
import './styles/Evaluation.scss';
import {
  PostSprintMeasure,
  PostSprintPriorActionItem,
  SprintMeasure,
} from 'types/Sprint';
import {Mission} from 'types/Mission';

import { useTranslation } from "react-i18next"

type EvaluationParams = {
  id?: string;
};

type PriorActionHeaderParams = {
  type: string;
  title: string;
  name: string;
  multiple?: boolean;
  autoheight?: boolean;
  options?: any[];
  width: number;
};

/**
 * Renders the Evaluation component.
 *
 * @return {React.Component} The rendered component.
 */
const Evaluation: React.FC = () => {
  const [missionData, setMissionData] = useState<Mission>({} as Mission);
  const [attendees, setAttendees] = useState([]);
  const [disable] = useState(false);
  const [priorActionHeaders, setPriorActionHeaders] = useState<
    PriorActionHeaderParams[]
  >([]);
  const {id} = useParams<EvaluationParams>();
  const dispatch = useDispatch();
  const refForm = useRef<HTMLButtonElement | null>();

  const [t] = useTranslation()

  const sprintId = useSelector((state) => {
    // @ts-ignore
    return state.sprint.value;
  });

  const mission = useSelector((state) => {
    // @ts-ignore
    return state.mission.value.id;
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: {errors},
  } = useForm();

  const {
    fields: fields_measures,
    append: append_measures,
    remove: remove_measures,
  } = useFieldArray({
    control,
    name: 'measures',
  });

  const {
    fields: fields_prior_action_items,
    append: append_prior_action_items,
    remove: remove_prior_action_items,
  } = useFieldArray({
    control,
    name: 'prior_action_items',
  });

  const notify = () => {
    getNotification('success', {
      header: '',
      body: 'The information has been saved successfully',
    });
  };

  /**
   * Save the evaluation data for a given set of parameters.
   *
   * @param {Object} params - The parameters for the evaluation.
   * @return {Promise} - A promise that resolves when the evaluation is saved.
   */
  const saveEvaluation = async (params: any) => {
    dialogStartSubject$.setSubject = true;
    params.mission && delete params.mission;
    params.engaged_team && delete params.engaged_team;
    try {
      const data: PostSprintMeasure[] = [];
      const measures_init = {
        mission_sprint: sprintId,
        measure_category: 'SPRINT_EVAL',
      };
      if (params.measures?.length === 0)
        params.measures.push({...measures_init});
      params.measures?.map((item: SprintMeasure) => {
        if (item?.created_by) delete item.created_by;
        (item?.id || item?.created) &&
          data.push({
            ...item,
            ...measures_init,
          });
      });
      delete params.measures;
      const updatedMeasures = await postSprintMeasures(data).call.then(res => res?.data ?? []);
      setValue('measures', updatedMeasures);

    } catch (error) {
      return;
    }

    try {
      const data: PostSprintPriorActionItem[] = [];
      const prior_init = {
        mission_sprint: sprintId,
      };
      if (params.prior_action_items?.length == 0) {
        params.prior_action_items.push({...prior_init});
        append_prior_action_items({...prior_init});
      }
      params.prior_action_items.map((item: any) => {
        if (item.person_responsible?.id)
          item.person_responsible = item.person_responsible?.id || 0;
        (item?.id || item?.created) &&
          data.push({
            ...item,
            ...prior_init,
          });
      });
      delete params.prior_action_items;
      await postSprintPriorActionItems(data).call;
      const updatedPriorActionItems= await postSprintPriorActionItems(data).call.then(res => res?.data ?? []);
      setValue('prior_action_items', updatedPriorActionItems);
    } catch (error) {
      return;
    }

    try {
      const data = {
        ...params,
        status: 'PLANNING',
        id: sprintId,
      };
      await patchSprints(data).call;
      notify();
    } catch (error) {}
    dialogStopSubject$.setSubject = false;
  };

  const loadMission = async () => {
    try {
      const res = await getMissionDetail(mission).call;
      setMissionData(res.data);
    } catch (error) {}
  };

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call;
      setAttendees(
        res.data?.results?.map((att: any) => ({value: att.id, label: att.name}))
      );
    } catch (error) {}
  };

  const loadEvaluation = async () => {
    if (sprintId) {
      await loadAttendees();
      reset();
      try {
      const res = await getSprint(sprintId).call;
        setValue(
          'sprint_call_responsible',
          res?.data?.sprint_call_responsible?.map((item) => item?.id)
        );
        setValue('success_is', res?.data?.success_is);
        setValue('failure_is', res?.data?.failure_is);
        res.data?.sprint_eval_measures?.map((item) => {
          append_measures(item);
          return item;
        });
        if (res.data?.prior_action_items?.length > 0) {
          res.data.prior_action_items.map((item) => {
            append_prior_action_items({
              ...item,
              person_responsible:
                item.person_responsible?.map((item) => item.id) || null,
            });
            return item;
          });
        } else {
          append_prior_action_items({created: true});
        }
      } catch (error) {}
    }
  };

  const activeSubmitForm = () => {
    handleSubmit(saveEvaluation)();
  };

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY),
    []
  );

  const [formTableData] = useState({
    control: control,
    debounceActive: debounceActive,
    errors: errors,
  });

  useEffect(() => {
    reset();
    loadEvaluation();
    loadMission();

    dispatch(getSprintById(sprintId));
    dispatch(getSprintWorkflowsBySprintId(sprintId));
    dispatch(fetchSprintWorkflowRoles());
  }, [sprintId]);

  useEffect(() => {
    setPriorActionHeaders([
      {
        type: 'inputMulti',
        autoheight: true, 
        title: 'Action Item',
        name: 'description',
        width: 11,
      },
      {
        type: 'date',
        title: 'Due Date',
        name: 'due_date',
        width: 6,
      },
      {
        type: 'select',
        title: 'Responsible',
        name: 'person_responsible',
        multiple: true,
        options: attendees,
        width: 5,
      },
    ]);
  }, [attendees]);

  return (
    <div className='evaluation'>
      <form onSubmit={handleSubmit(saveEvaluation)}>
        <div>
          <div className='flex justify-end font-18'>
            <div>
              <div>
                <button
                  type='submit'
                  className='btn-edit d-none'
                  ref={(e) => (refForm.current = e)}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${disable ? 'pointer-events-none' : ''}`}>
          <Row gutter={10} className='p-10'>
            <b>{t('commons.intervention')} Evaluation and Termination Plan</b>
          </Row>
          <Row gutter={10}>
            <Col xs={24}>
              <p>Success is:</p>
              <TextareaDebounce
                name='success_is'
                control={control}
                debounceAction={debounceActive}
              />
            </Col>
            <Col xs={24}>
              <p>Failure is:</p>
              <TextareaDebounce
                name='failure_is'
                control={control}
                debounceAction={debounceActive}
              />
            </Col>
            <div className='p-10 w-100'>
              <p>What metric(s) are you tracking?</p>
              <FormTable
                {...formTableData}
                // @ts-ignore
                design='list'
                name='measures'
                fields={fields_measures}
                headers={[
                  {
                    type: 'inputMulti',
                    title: 'Lead Measure',
                    name: 'name',
                    width: 5,
                  },
                  {
                    type: 'select',
                    title: '% or #',
                    name: 'measure_type',
                    options: [
                      {
                        value: 'PERCENTAGE',
                        label: '%',
                      },
                      {
                        value: 'NUMBER',
                        label: '#',
                      },
                    ],
                    width: 6,
                  },
                  {
                    type: 'text',
                    title: 'Current State',
                    name: 'current_state',
                  },
                  {
                    type: 'text',
                    title: 'Target',
                    name: 'target',
                  },
                  {
                    type: 'text',
                    title: 'Success Threshold',
                    name: 'success_threshold',
                  },
                  {
                    type: 'text',
                    title: 'Failure Threshold',
                    name: 'failure_threshold',
                  },
                ]}
                onAppend={append_measures}
                onRemove={remove_measures}
              />
            </div>
            <Col xs={24}>
              <p className='mb-10'>Who will make the call?</p>
              <Controller
                name='sprint_call_responsible'
                control={control}
                render={({field}) => (
                  <Select
                    {...field}
                    className={`select-class w-100 ${
                      errors.type ? 'border-error' : ''
                    }`}
                    placeholder='Select a type'
                    optionFilterProp='children'
                    mode='multiple'
                    bordered={false}
                    onChange={(net) => {
                      field.onChange(net);
                      debounceActive();
                    }}
                    options={attendees}
                  />
                )}
              />
            </Col>
          </Row>
          <div className='p-10'>
           <FormTable
              {...formTableData}
              // @ts-ignore
              design='list'
              name='prior_action_items'
              fields={fields_prior_action_items}
              headers={priorActionHeaders}
              onAppend={append_prior_action_items}
              onRemove={remove_prior_action_items}
            />
          </div>
        </div>
      </form>
      <DebounceLoading />
    </div>
  );
};

export default Evaluation;
